<template>
  <div class="tab-pane vivify fadeIn"
       style="height:500px;">
    <div id="dcdc-month-total-char-days"
         v-loading="loading"
         :style="{width:'100%',height:'100%','text-align':'center','line-height':'400px'}">{{$t("history.noData")}}</div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      myChart: null,
      loading: false,
    }
  },
  props: ['searchBtn', 'charData', 'resizeName'],
  watch: {
    searchBtn: {
      handler (searchBtn) {
        if (searchBtn != "") {
          this.pageLoad();
        }
      }
    },
    charData: {
      handler () {
        this.pageLoad();
      }
    },
    resizeName: {
      handler () {
        this.myChart.resize();
      }
    }
  },
  methods: {
    pageLoad () {
      if (this.charData != null && this.charData.length > 0) {
        this.detailChar();
      } else {
        var chartDom = document.getElementById('dcdc-month-total-char-days');
        chartDom.style.cssText = "text-align:center;color: #999; border: none;line-height: 400px;width: 100%; height: 100%; -webkit-tap-highlight-color: transparent; user-select: none; position: relative;";
        chartDom.innerHTML = this.$t("history.noData");
        chartDom.removeAttribute('_echarts_instance_');
      }
    },
    detailChar () {


      //时间
      let timesData = [];
      //
      let pvTotalData = [];
      this.loading = true;
      if (this.charData != null && this.charData.length > 0) {
        for (let i = 0; i < this.charData.length; i++) {
          let tt = ((this.charData[i]["getdatetime"]).split(' '))[0];
          tt = tt.split('-')
          timesData.push(tt[2]);
          pvTotalData.push(this.charData[i]["pvTotal"]);
        }
      }

      var chartDom = document.getElementById('dcdc-month-total-char-days');
      this.myChart = this.$echarts.init(chartDom, 'macarons');
      var option;
      option = {
        //图形上下右左边距设置
        grid: {
          left: '3%',
          right: '1%',
          bottom: '10%',
          containLabel: true
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: { type: 'cross' }
        },
        //color: ['#5470C6', '#91CC75', '#EE6666', '#fc8452', '#9a60b4', '#ea7ccc', '#91cc75'],
        legend: {

          orient: 'horizontal',
          bottom: 10,
          icon: 'stack',
        },
        xAxis: [
          {
            type: 'category',

            axisTick: {
              alignWithLabel: true
            },
            data: timesData
          }
        ],
        yAxis: [

          {
            type: 'value',
            // name: this.$t('history.dcdc.char.pvTotal') + '(kWh)',
            min: 0,
            position: 'left',
            axisLabel: {
              formatter: '{value} kWh'
            }
          }
        ],
        series: [
          {
            name: this.$t('history.dcdc.char.pvTotal') + '(kWh)',
            type: 'bar',
            yAxisIndex: 0,
            data: pvTotalData,

          }
        ]
      };
      option && this.myChart.setOption(option, true);
      this.loading = false;
      return true;
    }
  }
}
</script>