<template>
  <div class="tab-pane show vivify fadeIn active"
       style="height:500px;">
    <div id="ammter-pac-char-detail"
         v-loading="loading"
         :style="{width:'100%',height:'100%','text-align':'center','line-height':'400px'}">{{$t("history.noData")}}</div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      myChart: null,
    }
  },
  props: ['searchBtn', 'charData', 'resizeName'],
  watch: {
    searchBtn: {
      handler (searchBtn) {
        if (searchBtn != "") {
          this.pageLoad();
        }
      }
    },
    charData: {
      handler () {
        this.pageLoad();
      }
    },
    resizeName: {
      handler () {
        this.myChart.resize();
      }
    }
  },
  methods: {
    pageLoad () {
      if (this.charData != null && this.charData.length > 0) {
        this.detailChar();
      } else {
        var chartDom = document.getElementById('ammter-pac-char-detail');
        chartDom.style.cssText = "text-align:center;color: #999; border: none;line-height: 400px;width: 100%; height: 100%; -webkit-tap-highlight-color: transparent; user-select: none; position: relative;";
        chartDom.innerHTML = this.$t("history.noData");
        chartDom.removeAttribute('_echarts_instance_');
      }
    },
    detailChar () {

      console.log("ammter",this.charData);
      //时间
      let timesData = [];
      //交流功率
      let pacData = [];
      this.loading = true;
      let maxValue = 0;
      if (this.charData != null && this.charData.length > 0) {
        for (let i = 0; i < this.charData.length; i++) {
          let tt = ((this.charData[i]["getdatetime"]).split(' '))[1];
          tt = tt.split(':')
          timesData.push(tt[0] + ":" + tt[1]);
          pacData.push(this.charData[i]["pac"] == null ? 0 : this.charData[i]["pac"]);
          maxValue = parseFloat(this.charData[i]["pac"]) > maxValue ?  parseFloat(this.charData[i]["pac"]) : maxValue;
        }
      }
      maxValue = parseInt(maxValue);
       if(maxValue > 0){
             if(maxValue.toString().length == 1){
                maxValue = 10;
             }else if(maxValue.toString().length  == 2){
                maxValue = (parseInt(maxValue.toString().substring(0,1))+2) * 10
             }else if(maxValue.toString().length  == 3){
                maxValue = (parseInt(maxValue.toString().substring(0,2))+10) * 10
             }else if(maxValue.toString().length  == 4){
                maxValue = (parseInt(maxValue.toString().substring(0,3))+10) * 10
             }
        }
      var chartDom = document.getElementById('ammter-pac-char-detail');
      this.myChart = this.$echarts.init(chartDom, 'macarons');
      var option;
      option = {
        //图形上下右左边距设置
        grid: {
          left: '3%',
          right: '1%',
          bottom: '10%',
          containLabel: true
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: { type: 'cross' }
        },
        //color: ['#007bff', '#5470c6', '#91cc75', '#fc8452', '#9a60b4', '#ea7ccc', '#91cc75'],
        legend: {

          orient: 'horizontal',
          bottom: 10,
          icon: 'stack',
        },
        xAxis: [
          {
            type: 'category',

            axisTick: {
              alignWithLabel: true
            },
            data: timesData
          }
        ],
        yAxis: [
          {
            type: 'value',
            // name: this.$t('history.ari.char.temp') + '(°C)',
            // min: 0,
            // max: 25,
            position: 'left',
            axisLabel: {
              formatter: '{value} kW'
            }
          }
        ],
        series: [
          {
            name: this.$t('history.ammter.char.pac') + '(kW)',
            type: 'line',
            smooth: true,
            yAxisIndex: 0,
            data: pacData,
            emphasis: {
              focus: 'series'
            },
            areaStyle: {
              //color:'#007bff',
              opacity: 0.8
            },
            lineStyle: {
              width: 0
            },
            itemStyle: {
              opacity: 0,
            }
          }
        ]
      };
      option && this.myChart.setOption(option, true);
      this.loading = false;
      return true;
    }
  }
}
</script>