<template>

  <div class="sixbuttons-group" :class="cls" v-loading="loading">
    <div class="ml-auto">

      <el-row>
        <!-- 全部设备 -->
        <el-tooltip v-if="allShow==null||allShow==true" :disabled="true" class="item" :content="$t('deviceTypeGroup.all')" placement="bottom" effect="dark">
          <!-- 全部设备 -->
          <el-button type="aurora" size="small" :class="deviceTypeId=='loading'||deviceTypeId==null?'is-active gradient':''" @click="changeDeviceTypeId('loading')">{{$t('deviceTypeGroup.all')}}</el-button>
        </el-tooltip>
        <template v-for="(item) in deviceTypeList">
          <!-- v-if="item.isVisible==1" -->
          <el-tooltip :disabled="true" :key="item.deviceTypeId" class="item" :content="$t(deviceTypeName[item['deviceTypeId']])" placement="bottom" effect="dark">
            <el-button v-if="!multiple" type="aurora" size="small" :icon="deviceTypeIcon[item['deviceTypeId']]" @click="changeDeviceTypeId(item['deviceTypeId'])" :class="deviceTypeId==item['deviceTypeId']?'is-active gradient':''" round><span class="ml-1">
                {{$t(deviceTypeName[item['deviceTypeId']])}}
              </span></el-button>

            <el-button v-if="multiple" type="aurora" size="small" :icon="deviceTypeIcon[item['deviceTypeId']]" @click="changeDeviceTypeId(item['deviceTypeId'])" class="divElButton" :class="{'is-active gradient':item['stateus']}" round><span class="ml-1">
                {{$t(deviceTypeName[item['deviceTypeId']])}}
              </span></el-button>
          </el-tooltip>
        </template>
      </el-row>

    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {

      deviceTypeName: {
        "1": 'deviceTypeGroup.pcs',//"PCS",//PCS
        "2": 'deviceTypeGroup.dcdc',//"DCDC",//DCDC
        "3": 'deviceTypeGroup.bms',//"蓄電池",//蓄電池
        "5": 'deviceTypeGroup.ammeter',//"电表",//电表
        "4": 'deviceTypeGroup.ari',//"空调",//空调
        "6": 'deviceTypeGroup.fire',//"消防",//消防
      },
      deviceTypeIcon: {
        "1": "fad fa-archive",//PCS
        "2": "fad fa-tablet-rugged",//DCDC
        "3": "fad fa-car-battery",//蓄電池
        "5": "fad fa-tachometer-alt-fast",//电表
        "4": "fad fa-snow-blowing",//空调
        "6": "fad fa-fire-extinguisher",//消防
      },
      loading: false,
      deviceTypeId: "loading",
      deviceTypeIds:[],
      deviceTypeList: [],
      urlstr: "",
      setectPsId: "",
      call: "",
    }
  },
  //props:['deviceTypeList','cls'],
  props: ['multiple', "psId", 'cls', 'allShow', 'winopen', 'calls'],
  watch: {
    psId: {
      handler (psId) {
        this.setectPsId = psId;
        this.getQuickDeviceType();
      }
    },
    calls: {
      handler (calls) {
        this.call = calls;
        if (calls != "") {
          this.getQuickDeviceType();
        }
      }
    },
  },
  methods: {
    changeDeviceTypeId (deviceTypeId) {
      console.log(1234)
      if (deviceTypeId == "") {
        deviceTypeId = "loading";
      } else {
        if (this.multiple) {
          this.deviceTypeId="";
          for (let i = 0; i < this.deviceTypeList.length; i++) {
            if (this.deviceTypeList[i]["deviceTypeId"] == deviceTypeId) {
              if (this.deviceTypeList[i]["stateus"]) {
                // if (this.deviceTypeId != "" && this.deviceTypeId.indexOf(",") >= 0) {
                //   this.deviceTypeId = this.deviceTypeId.replace(deviceTypeId + ",", "")
                // }
                //移除指定设备类型
                const index = this.deviceTypeIds.indexOf(deviceTypeId);
                if (index > -1) {
                  this.deviceTypeIds.splice(index, 1);
                }
                this.deviceTypeList[i]["stateus"] = false;
              } else {
                this.deviceTypeIds.push(deviceTypeId);
                this.deviceTypeId = this.deviceTypeId + this.deviceTypeList[i]["deviceTypeId"] 
                this.deviceTypeList[i]["stateus"] = true;
                console.log(this.deviceTypeIds);
              }
            }
          }
          //this.multipleOrOnly(deviceTypeId)
        } else {
          //如果deviceTypeId=undefined，那么默认选中上一次的设备类型
          if(deviceTypeId != undefined){
              this.deviceTypeId = deviceTypeId;
          }
        }
      }
      if (this.call != "") {
        this.deviceTypeId = "";
      }
      this.$emit("deviceTypeIdChecked", this.deviceTypeId,this.deviceTypeIds);


    },
    async getQuickDeviceType () {
      try {
        this.urlstr = "/service-psinfo/psinfo/getDeviceTypeByPsId?psId=" + this.$store.state.psId;
        if (this.setectPsId != "" && this.setectPsId.indexOf(",") >= 0) {
          this.urlstr = "/service-psinfo/psinfo/getDeviceTypeByUserId?userId=" + window.sessionStorage.getItem("userId");
        }

        this.loading = true;
        const { data: res } = await this.$http.get(this.urlstr, {});
        if (res.status == 200) {
          if (this.multiple) {
            for (let i = 0; i < res.data.length; i++) {
              res.data[i]["stateus"] = false;
            }
          }

          this.deviceTypeList = res.data;
          console.info("deviceTypeList", this.deviceTypeList)
          this.loading = false;
          this.changeDeviceTypeId();
        }
        else {
          this.deviceTypeList = [];
          this.loading = false;
        }
      } catch (err) {
        this.loading = false;
      }

    },
  }


}
</script>

<style>
.divElButton:focus,
.divElButton:hover {
  color: var(--font-color) !important;
  border-color: #ccc !important;
  background-color: #fff !important;
}
</style>