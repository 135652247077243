<template>
  <div class="tab-pane show vivify fadeIn active"
       style="height:500px;">
    <div id="dcdc-ipv-char-detail"
         v-loading="loading"
         :style="{width:'100%',height:'100%','text-align':'center','line-height':'400px'}">{{$t("history.noData")}}</div>
  </div>
</template>
<script>
import {getMaxMinValue,getMaxMinSet} from '../../utils/tools.js'
export default {
  data () {
    return {
      myChart: null,
      loading: false,
      maxValueA: 0,
      minValueA: 0,
      maxValueV: 0,
      minValueV: 0
    }
  },
  props: ['searchBtn', 'charData', 'resizeName'],
  watch: {
    searchBtn: {
      handler (searchBtn) {
        if (searchBtn != "") {
          this.pageLoad();
        }
      }
    },
    charData: {
      handler () {
        this.pageLoad();
      }
    },
    resizeName: {
      handler () {
        this.myChart.resize();
      }
    }
  },
  methods: {
    pageLoad () {
      if (this.charData != null && this.charData.length > 0) {
        this.detailChar();
      } else {
        var chartDom = document.getElementById('dcdc-ipv-char-detail');
        chartDom.style.cssText = "text-align:center;color: #999; border: none;line-height: 400px;width: 100%; height: 100%; -webkit-tap-highlight-color: transparent; user-select: none; position: relative;";
        chartDom.innerHTML = this.$t("history.noData");
        chartDom.removeAttribute('_echarts_instance_');
      }
    },
    detailChar () {

      console.log(this.charData);
      //时间
      let timesData = [];
      //交流功率
      let pacData = [];
      //交流电压
      let vData = [];
      this.loading = true;
      let maxValueA = 0;
      let minValueA = 0;
      let maxValueV = 0;
      let minValueV = 0;
      if (this.charData != null && this.charData.length > 0) {
        for (let i = 0; i < this.charData.length; i++) {
          let tt = ((this.charData[i]["getdatetime"]).split(' '))[1];
          tt = tt.split(':')
          timesData.push(tt[0] + ":" + tt[1]);
          pacData.push(this.charData[i]["ipv"] == null ? 0 : this.charData[i]["ipv"]);
          vData.push(this.charData[i]["vpv"] == null ? 0 : this.charData[i]["vpv"]);
          maxValueA = parseFloat(this.charData[i]["ipv"]) > maxValueA ? parseFloat(this.charData[i]["ipv"]) : maxValueA;
          minValueA = parseFloat(this.charData[i]["ipv"]) < minValueA ? parseFloat(this.charData[i]["ipv"]) : minValueA;
          maxValueV = parseFloat(this.charData[i]["vpv"]) > maxValueV ? parseFloat(this.charData[i]["vpv"]) : maxValueV;
          minValueV = parseFloat(this.charData[i]["vpv"]) < minValueV ? parseFloat(this.charData[i]["vpv"]) : minValueV;
        }
      }
      let jsonMaxMinA = getMaxMinValue(maxValueA,minValueA)
      let jsonMaxMinV = getMaxMinValue(maxValueV,minValueV)
      this.maxValueA = jsonMaxMinA["max"];
      this.minValueA = jsonMaxMinA["min"];
      this.maxValueV = jsonMaxMinV["max"];
      this.minValueV = jsonMaxMinV["min"];
        if(jsonMaxMinA["flag"] || jsonMaxMinV["flag"]){
           let jsonMaxMinA = getMaxMinSet(maxValueA,minValueA)
           this.maxValueA = jsonMaxMinA["max"];
           this.minValueA = jsonMaxMinA["min"];
           let jsonMaxMinV = getMaxMinSet(maxValueV,minValueV)
           this.maxValueV = jsonMaxMinV["max"];
           this.minValueV = jsonMaxMinV["min"];
        }
      var chartDom = document.getElementById('dcdc-ipv-char-detail');
      this.myChart = this.$echarts.init(chartDom, 'macarons');
      var option;
      option = {
        //图形上下右左边距设置
        grid: {
          left: '3%',
          right: '1%',
          bottom: '10%',
          containLabel: true
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: { type: 'cross' }
        },
        // color: ['#007bff', '#5470c6', '#91cc75', '#fc8452', '#9a60b4', '#ea7ccc', '#91cc75'],
        legend: {

          orient: 'horizontal',
          bottom: 10,
          icon: 'stack',
        },
        xAxis: [
          {
            type: 'category',

            axisTick: {
              alignWithLabel: true
            },
            data: timesData
          }
        ],
        yAxis: [
          {
            type: 'value',
            // name: this.$t('history.dcdc.char.inCurrent') + this.$t("history.voltage"),
            min: this.minValueA,
            max: this.maxValueA,
            position: 'left',
            axisLabel: {
              formatter: '{value} A'
            }
          },
          {
            type: 'value',
            // name: 'V',
            min: this.maxValueV,
            max: this.minValueV,
            position: 'right',
            axisLabel: {
              formatter: '{value} V'
            }
          }
        ],
        series: [
          {
            name: this.$t('history.dcdc.char.inCurrent') + '(A)',
            type: 'line',
            smooth: true,
            yAxisIndex: 0,
            data: pacData,
            emphasis: {
              focus: 'series'
            },
            areaStyle: {
              //color:'#007bff',
              opacity: 0.8
            },
            lineStyle: {
              width: 0
            },
            itemStyle: {
              opacity: 0,
            }
          },
          {
            name: this.$t('history.dcdc.char.inVoltage') + '(V)',
            type: 'line',
            smooth: true,
            yAxisIndex: 1,
            data: vData,
            emphasis: {
              focus: 'series'
            },
            // areaStyle: {
            //   //color:'#007bff',
            //   opacity: 0.8
            // },
            // lineStyle: {
            //   width: 0
            // },
            // itemStyle: {
            //   opacity: 0,
            // }
          }

        ]
      };
      option && this.myChart.setOption(option, true);
      this.loading = false;
      return true;
    }
  }
}
</script>