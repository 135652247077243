// 删除cookie
export function getMaxMinValue(maxValue, minValue){
  let flag = false;
  maxValue = parseInt(maxValue);
  minValue = parseInt(minValue);
  if (maxValue > 0) {
    if (maxValue.toString().length == 1) {
      maxValue = 10;
    } else if (maxValue.toString().length == 2) {
      maxValue = (parseInt(maxValue.toString().substring(0, 1)) + 2) * 10
    } else if (maxValue.toString().length == 3) {
      maxValue = (parseInt(maxValue.toString().substring(0, 2)) + 10) * 10
    } else if (maxValue.toString().length == 4) {
      maxValue = (parseInt(maxValue.toString().substring(0, 3)) + 10) * 10
    } else if (this.maxValue.toString().length == 5) {
      maxValue = (parseInt(maxValue.toString().substring(0, 4)) + 10) * 10
    }
  }
  if (minValue < 0) {
    flag = true //有负数就按最大值
    minValue = Math.abs(minValue)
    if (minValue.toString().length == 1) {
      minValue = -10;
    } else if (minValue.toString().length == 2) {
      minValue = (parseInt(minValue.toString().substring(0, 1)) + (2)) * -10
    } else if (minValue.toString().length == 3) {
      minValue = (parseInt(minValue.toString().substring(0, 2)) + (10)) * -10
    } else if (minValue.toString().length == 4) {
      minValue = (parseInt(minValue.toString().substring(0, 3)) + (10)) * -10
    }
  }
  let jsonArr = {};
  jsonArr["max"] = maxValue
  jsonArr["min"] = minValue
  jsonArr["flag"] = flag
  return jsonArr;
}

export function getMaxMinSet(maxValue, minValue){
    let jsonArr = {};
    jsonArr["max"] = Math.abs(maxValue) >  Math.abs(minValue) ? Math.abs(maxValue) :  Math.abs(minValue)
    jsonArr["min"] = (Math.abs(maxValue) >  Math.abs(minValue) ? Math.abs(maxValue) :  Math.abs(minValue)) * -1
    return jsonArr;
}


