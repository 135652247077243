<template>
  <div class="tab-pane vivify fadeIn"
       id="ChargeVoltage"
       style="height:500px;">
    <div id="pcs-vac-char-detail"
         v-loading="loading"
         :style="{width:'100%',height:'100%','text-align':'center','line-height':'400px'}">{{$t("history.noData")}}</div>
  </div>
</template>
<script>
import {getMaxMinValue,getMaxMinSet} from '../../utils/tools.js'
export default {
  data () {
    return {
      myChart: null,
      loading: false,
      maxValueA: 0,
      minValueA: 0,
      maxValueV: 0,
      minValueV: 0
    };
  },
  props: ['searchBtn', "charData", "resizeName"],
  watch: {
    searchBtn: {
      handler (searchBtn) {
        if (searchBtn != "") {
          this.pageLoad();
        }
      }
    },
    charData: {
      handler () {
        this.pageLoad();
      }
    },
    resizeName: {
      handler () {
        this.myChart.resize();
      }
    }
  },
  methods: {
    pageLoad () {
      if (this.charData != null && this.charData.length > 0) {
        this.detailChar();
      } else {
        var chartDom = document.getElementById('pcs-vac-char-detail');
        chartDom.style.cssText = "text-align:center;color: #999; border: none;line-height: 400px;width: 100%; height: 100%; -webkit-tap-highlight-color: transparent; user-select: none; position: relative;";
        chartDom.innerHTML = this.$t("history.noData");
        chartDom.removeAttribute('_echarts_instance_');
      }
    },
    detailChar () {
      console.log(this.charData);
      //时间
      let timesData = [];

      //交流电流A
      let rIacData = [];
      //交流电流A
      let sIacData = [];
      //交流电流A
      let tIacData = [];

      //交流电压A
      let rVacData = [];
      //交流电压B
      let sVacData = [];
      //交流电压C
      let tVacData = [];
      let maxValueA = 0;
      let minValueA = 0;
      let maxValueV = 0;
      let minValueV = 0;
      this.loading = true;
      if (this.charData != null && this.charData.length > 0) {
        for (let i = 0; i < this.charData.length; i++) {
          let tt = this.charData[i]["getdatetime"].split(" ")[1];
          tt = tt.split(":");
          timesData.push(tt[0] + ":" + tt[1]);
          rIacData.push(this.charData[i]["rIac"]);
          sIacData.push(this.charData[i]["sIac"]);
          tIacData.push(this.charData[i]["tIac"]);
          rVacData.push(this.charData[i]["rVac"]);
          sVacData.push(this.charData[i]["sVac"]);
          tVacData.push(this.charData[i]["tVac"]);


         maxValueA = parseFloat(this.charData[i]["rIac"]) > maxValueA ?  parseFloat(this.charData[i]["rIac"]) : maxValueA;
         minValueA = parseFloat(this.charData[i]["rIac"]) < minValueA ?  parseFloat(this.charData[i]["rIac"]) : minValueA;
         maxValueA = parseFloat(this.charData[i]["sIac"]) > maxValueA ?  parseFloat(this.charData[i]["sIac"]) : maxValueA;
         minValueA = parseFloat(this.charData[i]["sIac"]) < minValueA ?  parseFloat(this.charData[i]["sIac"]) : minValueA;
         maxValueA = parseFloat(this.charData[i]["tIac"]) > maxValueA ?  parseFloat(this.charData[i]["tIac"]) : maxValueA;
         minValueA = parseFloat(this.charData[i]["tIac"]) < minValueA ?  parseFloat(this.charData[i]["tIac"]) : minValueA;
         maxValueV = parseFloat(this.charData[i]["rVac"]) > maxValueV ?  parseFloat(this.charData[i]["rVac"]) : maxValueV;
         minValueV = parseFloat(this.charData[i]["rVac"]) < minValueV ?  parseFloat(this.charData[i]["rVac"]) : minValueV;
         maxValueV = parseFloat(this.charData[i]["sVac"]) > maxValueV ?  parseFloat(this.charData[i]["sVac"]) : maxValueV;
         minValueV = parseFloat(this.charData[i]["sVac"]) < minValueV ?  parseFloat(this.charData[i]["sVac"]) : minValueV;
         maxValueV = parseFloat(this.charData[i]["tVac"]) > maxValueV ?  parseFloat(this.charData[i]["tVac"]) : maxValueV;
         minValueV = parseFloat(this.charData[i]["tVac"]) < minValueV ?  parseFloat(this.charData[i]["tVac"]) : minValueV;

        }
        let jsonMaxMinA = getMaxMinValue(maxValueA,minValueA)
        let jsonMaxMinV = getMaxMinValue(maxValueV,minValueV)
        this.maxValueA = jsonMaxMinA["max"];
        this.minValueA = jsonMaxMinA["min"];
        this.maxValueV = jsonMaxMinV["max"];
        this.minValueV = jsonMaxMinV["min"];
        if(jsonMaxMinA["flag"] || jsonMaxMinV["flag"]){
           let jsonMaxMinA = getMaxMinSet(maxValueA,minValueA)
           this.maxValueA = jsonMaxMinA["max"];
           this.minValueA = jsonMaxMinA["min"];
           let jsonMaxMinV = getMaxMinSet(maxValueV,minValueV)
           this.maxValueV = jsonMaxMinV["max"];
           this.minValueV = jsonMaxMinV["min"];
        }

      }
      var chartDom = document.getElementById("pcs-vac-char-detail");
      this.myChart = this.$echarts.init(chartDom, 'macarons');
      var option;
      option = {
        //图形上下右左边距设置
        grid: {
          left: "3%",
          right: "1%",
          bottom: "10%",
          containLabel: true
        },
        tooltip: {
          trigger: "axis",
          axisPointer: { type: "cross" }
        },
        // color: [
        //   "#007bff",
        //   "#5470c6",
        //   "#91cc75",
        //   "#fc8452",
        //   "#9a60b4",
        //   "#ea7ccc",
        //   "#91cc75"
        // ],
        legend: {
          orient: "horizontal",
          bottom: 10,
          icon: "stack"
        },
        xAxis: [
          {
            type: "category",

            axisTick: {
              alignWithLabel: true
            },
            data: timesData
          }
        ],
        yAxis: [
          {
            type: "value",
            min: this.minValueA,
            max: this.maxValueA,
            position: "left",
            axisLabel: {
              formatter: "{value} A"
            }
          },
           {
            type: 'value',
            // name: 'V',
            min: this.maxValueV,
            max: this.minValueV,
            position: 'right',
            axisLabel: {
              formatter: '{value} V'
            }
          }
        ],
        series: [
          {
            name: this.$t("history.ACCurrent") + "A(A)",
            type: "line",
            smooth: true,
            yAxisIndex: 0,
            data: rIacData,
            emphasis: {
              focus: "series"
            },
            areaStyle: {
              //color:'#007bff',
              opacity: 0.8
            },
            lineStyle: {
              width: 0
            },
            itemStyle: {
              opacity: 0
            }
          },
          {
            name: this.$t("history.ACCurrent") + "B(A)",
            type: "line",
            smooth: true,
            yAxisIndex: 0,
            data: sIacData,
            emphasis: {
              focus: "series"
            },
            areaStyle: {
              //color:'#007bff',
              opacity: 0.8
            },
            lineStyle: {
              width: 0
            },
            itemStyle: {
              opacity: 0
            }
          },
          {
            name: this.$t("history.ACCurrent") + "C(A)",
            type: "line",
            smooth: true,
            yAxisIndex: 0,
            data: tIacData,
            emphasis: {
              focus: "series"
            },
            areaStyle: {
              //color:'#007bff',
              opacity: 0.8
            },
            lineStyle: {
              width: 0
            },
            itemStyle: {
              opacity: 0
            }
          },
          {
            name: this.$t("history.ACVoltage") + "A(V)",
            type: "line",
            smooth: true,
            yAxisIndex: 1,
            data: rVacData,
            emphasis: {
              focus: "series"
            },
            // areaStyle: {},
            // lineStyle: {
            //   width: 0
            // },
            // itemStyle: {
            //   opacity: 0
            // }
          },
          {
            name: this.$t("history.ACVoltage") + "B(V)",
            type: "line",
            smooth: true,
            yAxisIndex: 1,
            data: sVacData,
            emphasis: {
              focus: "series"
            },
            // areaStyle: {},
            // lineStyle: {
            //   width: 0
            // },
            // itemStyle: {
            //   opacity: 0
            // }
          },
          {
            name: this.$t("history.ACVoltage") + "C(V)",
            type: "line",
            smooth: false,
            yAxisIndex: 1,
            data: tVacData,
            emphasis: {
              focus: "series"
            },
            // areaStyle: {},
            // lineStyle: {
            //   width: 0
            // },
            // itemStyle: {
            //   opacity: 0
            // }
          }
        ]
      };
      option && this.myChart.setOption(option, true);
      this.loading = false;
      return true;
    }
  }
};
</script>
