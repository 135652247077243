<template>
  <div class="col-12" v-loading="loading">
    <div class="card card-noborder">
      <div class="body">
        <div id="scrollbox">
          <div>
            <div class="list iconlist">
              <template v-for="item in deviceList">
                <div class="item " :class="deviceFlagClass(item.deviceFlag)" :key="item.deviceId">
                  <div>
                    <a href="javascript:void(0);">
                      <div class="icon-holder" @click="checkedDevice(item.deviceId, item.deviceTypeId)" :class="activDevice(item.deviceId) ? 'active' : ''">
                        <div class="icon">
                          <i class="fad " :class="deviceTypeIcon[item.deviceTypeId]"></i>
                        </div>
                        <span>{{ item.deviceName }}</span>
                      </div>
                    </a>
                  </div>
                </div>
              </template>
              <!-- <div class="item OK">
                <div>
                  <a href="javascript:void(0);">
                    <div class="icon-holder active">
                      <div class="icon">
                        <i class="fad fa-car-battery"></i>
                      </div>
                      <span> 1234567890 </span>
                    </div>
                  </a>
                </div>
              </div>
              <div class="item OK">
                <div>
                  <a href="javascript:void(0);">
                    <div class="icon-holder">
                      <div class="icon">
                        <i class="fad fa-archive"></i>
                      </div>
                      <span> 1234567890 </span>
                    </div>
                  </a>
                </div>
              </div>
              <div class="item OK">
                <div>
                  <a href="javascript:void(0);">
                    <div class="icon-holder">
                      <div class="icon">
                        <i class="fad fa-tachometer-alt-fast"></i>
                      </div>
                      <span> 1234567890 </span>
                    </div>
                  </a>
                </div>
              </div>
              <div class="item OK">
                <div>
                  <a href="javascript:void(0);">
                    <div class="icon-holder">
                      <div class="icon">
                        <i class="fad fa-tablet-rugged"></i>
                      </div>
                      <span> 1234567890 </span>
                    </div>
                  </a>
                </div>
              </div>
              <div class="item OK">
                <div>
                  <a href="javascript:void(0);">
                    <div class="icon-holder">
                      <div class="icon">
                        <i class="fad fa-snow-blowing"></i>
                      </div>
                      <span> 1234567890 </span>
                    </div>
                  </a>
                </div>
              </div>
              <div class="item OK">
                <div>
                  <a href="javascript:void(0);">
                    <div class="icon-holder">
                      <div class="icon">
                        <i class="fad fa-fire-extinguisher"></i>
                      </div>
                      <span> 1234567890 </span>
                    </div>
                  </a>
                </div>
              </div>
              <div class="item ERRO">
                <div>
                  <a href="javascript:void(0);">
                    <div class="icon-holder active">
                      <div class="icon">
                        <i class="fad fa-car-battery"></i>
                      </div>
                      <span> 1234567890 </span>
                    </div>
                  </a>
                </div>
              </div>
              <div class="item ERRO">
                <div>
                  <a href="javascript:void(0);">
                    <div class="icon-holder">
                      <div class="icon">
                        <i class="fad fa-archive"></i>
                      </div>
                      <span> 1234567890 </span>
                    </div>
                  </a>
                </div>
              </div>
              <div class="item ERRO">
                <div>
                  <a href="javascript:void(0);">
                    <div class="icon-holder">
                      <div class="icon">
                        <i class="fad fa-tachometer-alt-fast"></i>
                      </div>
                      <span> 1234567890 </span>
                    </div>
                  </a>
                </div>
              </div>
              <div class="item ERRO">
                <div>
                  <a href="javascript:void(0);">
                    <div class="icon-holder">
                      <div class="icon">
                        <i class="fad fa-tablet-rugged"></i>
                      </div>
                      <span> 1234567890 </span>
                    </div>
                  </a>
                </div>
              </div>
              <div class="item ERRO">
                <div>
                  <a href="javascript:void(0);">
                    <div class="icon-holder">
                      <div class="icon">
                        <i class="fad fa-snow-blowing"></i>
                      </div>
                      <span> 1234567890 </span>
                    </div>
                  </a>
                </div>
              </div>
              <div class="item ERRO">
                <div>
                  <a href="javascript:void(0);">
                    <div class="icon-holder">
                      <div class="icon">
                        <i class="fad fa-fire-extinguisher"></i>
                      </div>
                      <span> 1234567890 </span>
                    </div>
                  </a>
                </div>
              </div>
              <div class="item STOP">
                <div>
                  <a href="javascript:void(0);">
                    <div class="icon-holder active">
                      <div class="icon">
                        <i class="fad fa-car-battery"></i>
                      </div>
                      <span> 1234567890 </span>
                    </div>
                  </a>
                </div>
              </div>
              <div class="item STOP">
                <div>
                  <a href="javascript:void(0);">
                    <div class="icon-holder">
                      <div class="icon">
                        <i class="fad fa-archive"></i>
                      </div>
                      <span> 1234567890 </span>
                    </div>
                  </a>
                </div>
              </div>
              <div class="item STOP">
                <div>
                  <a href="javascript:void(0);">
                    <div class="icon-holder">
                      <div class="icon">
                        <i class="fad fa-tachometer-alt-fast"></i>
                      </div>
                      <span> 1234567890 </span>
                    </div>
                  </a>
                </div>
              </div>
              <div class="item STOP">
                <div>
                  <a href="javascript:void(0);">
                    <div class="icon-holder">
                      <div class="icon">
                        <i class="fad fa-tablet-rugged"></i>
                      </div>
                      <span> 1234567890 </span>
                    </div>
                  </a>
                </div>
              </div>
              <div class="item STOP">
                <div>
                  <a href="javascript:void(0);">
                    <div class="icon-holder">
                      <div class="icon">
                        <i class="fad fa-snow-blowing"></i>
                      </div>
                      <span> 1234567890 </span>
                    </div>
                  </a>
                </div>
              </div>
              <div class="item STOP">
                <div>
                  <a href="javascript:void(0);">
                    <div class="icon-holder">
                      <div class="icon">
                        <i class="fad fa-fire-extinguisher"></i>
                      </div>
                      <span> 1234567890 </span>
                    </div>
                  </a>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "devices",
  data () {
    return {
      deviceCount:0,
      loadFlag:false,
      queryflag: false,
      loading: false,
      deviceTypeIcon: {
        "1": "fad fa-archive", //PCS
        "2": "fad fa-tablet-rugged", //DCDC
        "3": "fad fa-car-battery", //蓄電池
        "5": "fad fa-tachometer-alt-fast", //电表
        "4": "fad fa-snow-blowing", //空调
        "6": "fad fa-fire-extinguisher" //消防
      },
      deviceList: [],
      deviceId: null,
      deviceTypeId: null,

      //当前选中的电站ID
      psid: null,
    };
  },
  // props:["deviceList"],
  props: ["psId", "checkedDeviceTypeId"],
  watch: {
    psId: {
      handler (psId) {
        this.psid = psId;
        if (this.psid != null) {
          this.deviceId = null;
          this.deviceTypeId = null;
          this.getDevicesByPsIdTypeId();
        }
      }
    },
    checkedDeviceTypeId: {
      handler (checkedDeviceTypeId) {
        if (checkedDeviceTypeId != undefined) {
          this.deviceId = null;
          this.deviceTypeId = null;
          this.loadFlag = true;
          this.getDevicesByPsIdTypeId();
        }
      }
    },
  },
  methods: {
    //根据设备运行状态返回样式
    deviceFlagClass (deviceFlag) {
      let cls = "";
      switch (deviceFlag) {
        case 0:
          cls = "OK"; //正常
          break;
        case 1:
          cls = "SLASH"; //离线
          break;
        case 3:
          cls = "STOP"; //停机
          break;
        case 4:
          cls = "ERRO"; //故障
          break;
        default:
          cls = "OK";
          break;
      }
      return cls;
    },
    //绑定选中设备样式，默认选中第一项
    activDevice (deviceId) {
      if (this.deviceId == deviceId) {
        return true;
      }
    },
    //选择设备事件
    checkedDevice (deviceId, deviceTypeId) {
      this.deviceId = deviceId;
      $(this.deviceList).each(function(i,val){
          if(val.deviceId==deviceId){
            window.sessionStorage.setItem("deviceInfo",JSON.stringify(val));
          }
      })
      this.deviceTypeId = deviceTypeId;
      this.$emit("deviceChecked", this.deviceId, this.deviceTypeId);
    },
    //获取设备列表
    async getDevicesByPsIdTypeId () {
      var _this = this;
      try {
        this.loading = true;
        let deviceTypeId = "";
        if (typeof this.checkedDeviceTypeId == "string") {
          let temp = this.checkedDeviceTypeId.split(",");
          if (temp.length > 1) {
            deviceTypeId = "";
          }
        } else if (typeof this.checkedDeviceTypeId == "number") {
          deviceTypeId = this.checkedDeviceTypeId;
        }
        // let deviceTypeId= this.checkedDeviceTypeId==null?'':this.checkedDeviceTypeId;
        const { data: res } = await this.$http.get(
          "/service-psinfo/psinfo/getDevicesByPsIdTypeId?deviceTypeId=" +
          deviceTypeId +
          "&psId=" +
          this.$store.state.psId,
          {}
        );

        if (res.status == 200) {
          this.deviceList = res.data;
          if (this.deviceList != null && this.deviceList.length > 0) {
            _this.deviceCount = this.deviceList.length
            if(_this.loadFlag){
                setTimeout(_this.scrollboxLoad(),5000)
                _this.loadFlag = false
            }
            //获取url传的deviceId
            if (window.sessionStorage.getItem("seldeviceId") != undefined) {
              this.deviceId = window.sessionStorage.getItem("seldeviceId");
              window.sessionStorage.removeItem("seldeviceId");
            }
            //获取url传的deviceTypeId
            if (window.sessionStorage.getItem("seldevicetypeid") != undefined) {
              this.deviceTypeId = window.sessionStorage.getItem("seldevicetypeid");
              window.sessionStorage.removeItem("seldevicetypeid");
            }
            if (this.deviceId == null && this.deviceTypeId == null) {
              this.deviceId = this.deviceList[0]["deviceId"];
              this.deviceTypeId = this.deviceList[0]["deviceTypeId"];
            }


            this.checkedDevice(this.deviceId, this.deviceTypeId);

          }
          this.loading = false;
        } else {
          this.deviceList = [];
          this.loading = false;
        }
      } catch (err) {
        this.loading = false;
      }
    },
    scrollboxLoad(){
      	let width
        let margin=1;
       // let size=$("#scrollbox .item").length;
        if(this.deviceCount>0){
          width= this.deviceCount*102+(this.deviceCount-1)*margin;
        }
        var oScrollbar = new TScrollBar('scrollbox', {
            direction: 'horizontal',
            width: width
        })

        $(".iconlist").attr("style","position: relative;width:"+width+"px;")
        if(width < $("#scrollbox").width()){
            $(".t-scrollbar__wrap").removeAttr("style");
           $(".t-scrollbar__bar").remove();
        }
    }
  }
};
</script>
