$(function() {

	let width
	let margin=1;
	let size=$("#scrollbox .item").length;
	if(size>0){
		width= size*100+(size-1)*margin;
	}
  
		var oScrollbar = new TScrollBar('scrollbox', {
			direction: 'horizontal',
			width: width
		})

});