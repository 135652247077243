<template>
  <div class="row clearfix">
    <div class="col-md-12">
      <div class="card">
        <div class="body m-b--15">
          <div class="row clearfix">
            <div class="col-md-2">
              <div class="form-group c_form_group HistoryDate">
                <label>
                  {{ $t("history.DatePicker.ChooseYear") }}
                </label>
                <div class="input-group">
                  <!-- <input id="DataYear" data-provide="datepicker" data-date-autoclose="true" class="form-control" v-model="year" placeholder="请选择年份"> -->
                  <el-date-picker v-model="yearCheck"
                                  type="year"
                                  :clearable="false"
                                  :editable="false"
                                  value-format="yyyy"
                                  @change="yearChange"
                                  :picker-options="pickerOptions"
                                  placeholder="选择日期"
                                  style="width:100%"></el-date-picker>
                </div>
              </div>
            </div>
            <div class="row clearfix col-md-10">
              <nav aria-label="..."
                   class="col-12 mt-2">
                <el-row>
                   <el-col :span="1" style="text-align:center;padding:0.55rem 0rem"><small>{{ $t("history.DatePicker.ChooseMonth") }}:</small></el-col>
                   <template v-for="month of 12">
                    <el-col class="page-item" :span="1" :key="month" style="padding:0px;" :class="[monthEvents(month)?'':'button-splash',parseInt(monthCheck)==month?'active splash':'']"><a class="page-link" @click="checkMonth(month)" 
                         href="javascript:void(0);">{{month}}</a></el-col>
                   </template>
                 </el-row>
                <!-- <ul class="pagination pagination-sm">
                  <span style="line-height:30px"><small>{{ $t("history.DatePicker.ChooseMonth") }}:</small></span>
                  <template v-for="month of 12">
                    <li :key="month"
                        class="page-item"
                        @click="checkMonth(month)"
                        :class="[monthEvents(month)?'':'button-splash',parseInt(monthCheck)==month?'active splash':'']"><a class="page-link"
                         href="javascript:void(0);">{{month}}</a></li>
                  </template>
                </ul> -->
              </nav>

              <nav aria-label="..."
                   class="col-12">
                <el-row>
                   <el-col :span="1" style="text-align:center;padding:0.55rem 0rem"><small>{{ $t("history.DatePicker.ChooseDay") }}:</small></el-col>
                   <template v-for="index of days">
                    <el-col :span="1" :key="index" style="padding:0px;"
                       class="page-item"  :class="[parseInt(dayCheck)==index?'active':'']"><a class="page-link"
                         href="javascript:void(0);" @click="checkDay(index)">{{index}}</a></el-col>
                   </template>
                </el-row>
                <!-- <ul class="pagination pagination-sm" style="width:max-content">
                  <span style="line-height:30px"><small>{{ $t("history.DatePicker.ChooseDay") }}:</small></span> 
                  <template v-for="index of days">
                    <li :key="index"
                        class="page-item" 
                        @click="checkDay(index)"
                        :class="[parseInt(dayCheck)==index?'active':'']"><a class="page-link"
                         href="javascript:void(0);">{{index}}</a></li>
                  </template>
                </ul> -->
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      searchType: ["year", "month", "day", "resetFlags"],
      searchTypeCheck: null,
      yearCheck: '2019',
      monthCheck: '',
      dayCheck: '',
      days: 10,
      months: 12,
      nowDay: 0,
      resetFlag: false,
      pickerOptions: {
        disabledDate (time) {
          let buildDate = window.sessionStorage.getItem("buildDate");
          let dts = buildDate.split("-");
          let dt = new Date(parseInt(dts[0]) - 1, '01', '01');
          return time.getTime() >= Date.now() || time.getTime() <= dt.getTime();
        }
      },
    }
  },

  props: ['resetFlags', 'buildDate', "deviceId", "psId", "checkedDeviceTypeId"],
  watch: {
    resetFlags: {
      handler (resetFlags) {
        this.resetFlag = resetFlags
      }
    },
    buildDate: {
      handler (buildDate) {
            window.sessionStorage.setItem("buildDate", buildDate);
            if(this.resetFlag){
              let dt = new Date();
              this.yearCheck = dt.getFullYear().toString();
              this.monthCheck = (dt.getMonth() + 1).toString();
              this.dayCheck = dt.getDate().toString();
              this.days = this.getCountDays(this.monthCheck);
              if (this.searchTypeCheck == null) this.searchTypeCheck = this.searchType[2];
              this.$emit("searchDateChange", this.yearCheck + "-" + this.monthCheck + '-' + this.dayCheck, this.searchTypeCheck);
            }else{
               this.$emit("searchDateChange", this.yearCheck + "-" + this.monthCheck + '-' + (this.dayCheck == null ? '01' : this.dayCheck), this.searchTypeCheck);
            }
           
      }
    },
    deviceId: {
      handler () {

        // let dt=new Date();
        // this.yearCheck=dt.getFullYear().toString();
        // this.monthCheck=(dt.getMonth()+1).toString();
        // this.dayCheck=dt.getDate().toString();
        // this.days=this.getCountDays(this.monthCheck);
        //if (this.searchTypeCheck == null) this.searchTypeCheck = this.searchType[2];
        //alert(this.searchTypeCheck)
        this.$emit("searchDateChange", this.yearCheck + "-" + (this.monthCheck == null ? '01' : this.monthCheck) + '-' + (this.dayCheck == null ? '01' : this.dayCheck), this.searchTypeCheck);

      }
    },
    psId: {
      handler () {
        // let dt = new Date();
        // this.yearCheck = dt.getFullYear().toString();
        // this.monthCheck = (dt.getMonth() + 1).toString();
        // this.dayCheck = dt.getDate().toString();
        // this.days = this.getCountDays(this.monthCheck);
        this.$emit("searchDateChange", this.yearCheck + "-" + this.monthCheck + '-' + (this.dayCheck == null ? '01' : this.dayCheck), this.searchTypeCheck);

      }
    },
    checkedDeviceTypeId: {
      handler () {
        //   let dt=new Date();
        // this.yearCheck=dt.getFullYear().toString();
        // this.monthCheck=(dt.getMonth()+1).toString();
        // this.dayCheck=dt.getDate().toString();
        // this.days=this.getCountDays(this.monthCheck);
        //if(!this.resetFlag){
        //设备类型切换时
        this.$emit("searchDateChange", this.yearCheck + "-" + this.monthCheck + '-' + (this.dayCheck == null ? '01' : this.dayCheck), this.searchTypeCheck);
        //}       
      }
    },
  },
  // created(){
  //   let dt=new Date();
  //   this.yearCheck=dt.getFullYear().toString();
  //   this.monthCheck=(dt.getMonth()+1).toString();
  //   this.dayCheck=dt.getDate();
  //   this.checkMonth(this.monthCheck);
  // },
  methods: {
    yearChange () {
      if (this.buildDate != null) {
        this.monthCheck = null;
        this.dayCheck = null;
        this.days = 0;
        //  let dt=new Date();
        // if( dt.getFullYear().toString()==this.yearCheck){
        //    this.monthCheck=(dt.getMonth()+1).toString();
        //    this.dayCheck=dt.getDate().toString();
        // }
        // else{
        //   this.monthCheck=null;
        //   this.dayCheck=null;
        //   this.days=0;
        // }
      }
      this.searchTypeCheck = this.searchType[0];

      this.$emit("searchDateChange", this.yearCheck + "-01-01", this.searchTypeCheck);

    },
    monthEvents (month) {
      let dt = new Date();
      let minMonth = 1;
      let maxMonth = 13;
      if (this.buildDate != null) {
        let dts = this.buildDate.split("-");
        if (!typeof (this.yearCheck) == "string") {
          this.yearCheck = this.year.getFullYear();
        }
        if (this.yearCheck < dts[0]) {
          minMonth = 13;
        }
        if (this.yearCheck == dts[0]) {
          minMonth = dts[1];
        }
        if (this.yearCheck == dt.getFullYear()) {
          maxMonth = dt.getMonth() + 1;
        }
      }
      if (month >= minMonth && month <= maxMonth) {

        return true;
      }
      return false;
    },
    checkMonth (month) {
      this.monthCheck = month;
      this.dayCheck = null;
      this.days = this.getCountDays(month);
      this.searchTypeCheck = this.searchType[1];
      this.$emit("searchDateChange", this.yearCheck + "-" + this.monthCheck + '-' + (this.dayCheck == null ? '01' : this.dayCheck), this.searchTypeCheck);




      //alert(this.monthCheck);
    },
    checkDay (day) {
      this.dayCheck = day;
      this.searchTypeCheck = this.searchType[2];
      this.$emit("searchDateChange", this.yearCheck + "-" + this.monthCheck + '-' + (this.dayCheck == null ? '01' : this.dayCheck), this.searchTypeCheck);
    },
    //绑定日期每月下有几天
    getCountDays (month) {

      var curDate = new Date();




      if (this.yearCheck == curDate.getFullYear() && this.monthCheck == (curDate.getMonth() + 1)) {
        // 返回当前月份的天数
        return curDate.getDate();
      }
      else {
        if (this.buildDate != null) {
          let dts = this.buildDate.split("-");
          curDate = new Date(dts[0], month - 1, dts[2]);
        }
        // 获取当前月份
        var curMonth = curDate.getMonth();
        // 实际月份比curMonth大1，下面将月份设置为下一个月
        curDate.setMonth(curMonth + 1);
        // 将日期设置为0，表示自动计算为上个月（这里指的是当前月份）的最后一天
        curDate.setDate(0);
        // 返回当前月份的天数
        return curDate.getDate();
      }

    },

  }
}
</script>

<style>
   .page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.1rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #007bff;
    background-color: #fff;
    border: 1px solid #dee2e6;
    text-align: center;
    font-size:0.55rem;
}

</style>