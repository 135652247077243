<template>

  <div id="main-content" class="main-content-normal" :class="{'main-content-bigger':$store.state.mainBig}">
    <div class="container-fluid">
      <div class="block-header">
        <div class="row clearfix">
          <div class="col-lg-4 col-md-12 col-sm-12">
            <h1>
              <!-- 设备报表 -->
              {{ $t("sidebar.menu.211112BD36S35D40") }}
            </h1>
          </div>
        </div>
      </div>

      <div class="row clearfix mt-4">
        <div class="col-xl-12">
          <div class="card">

            <div class="card-header">
              <div class="row clearfix">
                <div class="col-lg-12 col-lg-auto">
                  <div class="row clearfix">
                    <div class="col-md-4 col-md-auto text-right">
                      <select-ps-id-view :cls="''" @psIdChange="handlePsIdChange" />
                    </div>
                    <div class="col-md-8 col-md-auto text-right">
                      <group-device-type :cls="''" :psId="psId" @deviceTypeIdChecked="handleDeviceTypeChange" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card-body">
              <devices :psId="devPsId" :checkedDeviceTypeId="checkedDeviceTypeId" @deviceChecked="handleDeviceChange" />
            </div>
          </div>
        </div>
      </div>
      <!-- 时间平铺效果 -->
      <select-time :psId="devPsId" :checkedDeviceTypeId="checkedDeviceTypeId" :deviceId="deviceId" :buildDate="buildDate" :resetFlags="resetFlag" @searchDateChange="handleSearchDateChange" />
      <div class="row clearfix">
        <div class="col-md-12">
          <div class="card">
            <div class="body">
              <!-- PCS 日月年图表 -->
              <div class="row clearfix">
                <div class="col-lg-12" v-if="deviceTypeId == 1 && searchType == 'day'">
                  <el-tabs v-model="char.activeName">
                    <!-- 充/放电功率 -->
                    <el-tab-pane :label="$t('history.inOutPower')" name="ChargePcsPac">
                      <pcs-pac-detail-char v-loading="loading" :charData="char.pcsDetail" :searchBtn="bntNumber" :resizeName="char.activeName" />
                    </el-tab-pane>
                    <!-- 交流电流 -->
                    <el-tab-pane :label="$t('history.ACCurrent')+$t('history.&ACVoltage')" name="ChargeVoltage">
                      <pcs-iac-detail-char v-loading="loading" :charData="char.pcsDetail" :searchBtn="bntNumber" :resizeName="char.activeName" />
                    </el-tab-pane>
                    <!-- 交流电压 -->
                    <!-- 温度 -->
                    <el-tab-pane :label="$t('history.temp')" name="pcsTemp">
                      <pcs-temp-detail-char v-loading="loading" :charData="char.pcsDetail" :searchBtn="bntNumber" :resizeName="char.activeName" />
                    </el-tab-pane>
                    <!-- 温度 -->
                    <!-- <el-tab-pane :label="$t('history.ACVoltage')" name="ChargeCurrent">
                      <pcs-vac-detail-char v-loading="loading" :charData="char.pcsDetail" :resizeName="char.activeName" />
                    </el-tab-pane> -->
                  </el-tabs>

                </div>

                <div class="col-lg-12" v-if="deviceTypeId == 1 && searchType == 'month'">
                  <el-tabs v-model="char.activePcsDayName">
                    <!-- 每日充/放电量 -->
                    <el-tab-pane :label="$t('history.dayInOutTotalPac')" name="ChargePcsDays">
                      <pcs-total-month-char v-loading="loading" :searchBtn="bntNumber" :charData="char.pcsDays" />
                    </el-tab-pane>
                  </el-tabs>
                </div>

                <div class="col-lg-12" v-if="deviceTypeId == 1 && searchType == 'year'">
                  <el-tabs v-model="char.activePcsMonthName">
                    <!-- 每月充/放电量 -->
                    <el-tab-pane :label="$t('history.monthInOutTotalPac')" name="ChargePcsMonths">
                      <pcs-total-char v-loading="loading" :searchBtn="bntNumber" :charData="char.pcsMonths" />
                    </el-tab-pane>
                  </el-tabs>
                </div>

                <!-- DcDc 日月年图表 -->

                <div class="col-lg-12" v-if="deviceTypeId == 2 && searchType == 'day'">
                  <el-tabs v-model="char.activeDcDcName">
                    <!-- 输入功率 -->
                    <el-tab-pane :label="$t('history.inPower')" name="ChargeDcDcPpv">
                      <dc-dc-ppv-detail-char v-loading="loading" :searchBtn="bntNumber" :charData="char.dcdcDetail" :resizeName="char.activeDcDcName" />
                    </el-tab-pane>
                    <!-- 输入电压 -->
                    <!-- <el-tab-pane :label="$t('history.inVoltage')" name="ChargeDcDcVpv">
                      <dc-dc-vpv-detail-char v-loading="loading" :searchBtn="bntNumber" :charData="char.dcdcDetail" :resizeName="char.activeDcDcName" />
                    </el-tab-pane> -->
                    <!-- 输入电流 -->
                    <el-tab-pane :label="$t('history.inCurrent')+$t('history.&ACVoltage')" name="ChargeDcDcIpv">
                      <dc-dc-ipv-detail-char v-loading="loading" :searchBtn="bntNumber" :charData="char.dcdcDetail" :resizeName="char.activeDcDcName" />
                    </el-tab-pane>
                    <!-- 温度 -->
                    <el-tab-pane :label="$t('history.temp')" name="dcdcTemp">
                      <dcdc-temp-detail-char v-loading="loading" :charData="char.dcdcDetail" :searchBtn="bntNumber" :resizeName="char.activeDcDcName" />
                    </el-tab-pane>
                    <!-- 温度 -->
                  </el-tabs>
                </div>

                <div class="col-lg-12" v-if="deviceTypeId == 2 && searchType == 'month'">
                  <el-tabs v-model="char.activeDcDcDayName">
                    <!-- 每日发电量 -->
                    <el-tab-pane :label="$t('history.dayTotalPac')" name="ChargeDcDcDays">
                      <dc-dc-total-month-char v-loading="loading" :searchBtn="bntNumber" :charData="char.dcdcDays" />
                    </el-tab-pane>
                  </el-tabs>
                </div>

                <div class="col-lg-12" v-if="deviceTypeId == 2 && searchType == 'year'">
                  <el-tabs v-model="char.activeDcDcMonthName">
                    <!-- 每月发电量 -->
                    <el-tab-pane :label="$t('history.monthTotalPac')" name="ChargeDcDcMonths">
                      <dc-dc-total-char v-loading="loading" :searchBtn="bntNumber" :charData="char.dcdcMonths" />
                    </el-tab-pane>
                  </el-tabs>
                </div>

                <!-- BMS 日月年图表 -->
                <div class="col-lg-12" v-if="deviceTypeId == 3 && searchType == 'day'">
                  <el-tabs v-model="char.activeBmsName">
                    <!-- 充/放电功率 -->
                    <el-tab-pane :label="$t('history.inOutPower')+'＆SOC'" name="ChargeBmsBcp">
                      <bms-bcp-detail-char v-loading="loading" :searchBtn="bntNumber" :charData="char.bmsDetail" :resizeName="char.activeBmsName" />
                    </el-tab-pane>
                    <!-- 电压 -->
                    <el-tab-pane :label="$t('history.voltage')" name="ChargeBmsBV">
                      <bms-bv-detail-char v-loading="loading" :searchBtn="bntNumber" :charData="char.bmsDetail" :resizeName="char.activeBmsName" />
                    </el-tab-pane>
                    <!-- 充/放电电流 -->
                    <el-tab-pane :label="$t('history.inOutCurrent')" name="ChargeBmsBcc">
                      <bms-bcc-detail-char v-loading="loading" :searchBtn="bntNumber" :charData="char.bmsDetail" :resizeName="char.activeBmsName" />
                    </el-tab-pane>
                    <!-- 温度 -->
                    <el-tab-pane :label="$t('history.temp')" name="ChargeBmsbt">
                      <bms-bt-detail-char v-loading="loading" :searchBtn="bntNumber" :charData="char.bmsDetail" :resizeName="char.activeBmsName" />
                    </el-tab-pane>
                    <!-- SOC -->
                    <!-- <el-tab-pane :label="$t('history.soc')" name="ChargeBmsSoc">
                      <bms-soc-detail-char v-loading="loading" :searchBtn="bntNumber" :charData="char.bmsDetail" :resizeName="char.activeBmsName" />
                    </el-tab-pane> -->
                  </el-tabs>
                </div>

                <div class="col-lg-12" v-if="deviceTypeId == 3 && searchType == 'month'">
                  <!-- <el-alert :title="$t('history.notSupport')"  type="info" show-icon :closable="false"></el-alert> -->
                  <bms-total-month-char v-loading="loading" :searchBtn="bntNumber" :charData="char.bmsDays"></bms-total-month-char>
                </div>

                <div class="col-lg-12" v-if="deviceTypeId == 3 && searchType == 'year'">
                  <el-alert :title="$t('history.notSupport')" type="info" show-icon :closable="false"></el-alert>
                </div>

                <!-- 空调 日月年图表 -->
                <div class="col-lg-12" v-if="deviceTypeId == 4 && searchType == 'day'">
                  <el-tabs v-model="char.activeAirName">
                    <!-- 室内温度 -->
                    <el-tab-pane :label="$t('history.indoorTemp')" name="ChargeAirTemp">
                      <air-indoor-temp-detail-char v-loading="loading" :searchBtn="bntNumber" :charData="char.airDetail" :resizeName="char.activeAirName" />
                    </el-tab-pane>
                  </el-tabs>
                </div>

                <div class="col-lg-12" v-if="deviceTypeId == 4 && searchType == 'month'">
                  <!-- <el-alert :title="$t('history.notSupport')" type="info" show-icon :closable="false"></el-alert> -->
                  <air-total-month-char v-loading="loading" :searchBtn="bntNumber" :charData="char.airDays"></air-total-month-char>
                </div>

                <div class="col-lg-12" v-if="deviceTypeId == 4 && searchType == 'year'">
                  <el-alert :title="$t('history.notSupport')" type="info" show-icon :closable="false"></el-alert>
                </div>

                <!-- 电表 日月年图表 -->
                <div class="col-lg-12" v-if="deviceTypeId == 5 && searchType == 'day'">
                  <el-tabs v-model="char.activeAmmterName">
                    <!-- <el-alert :title="$t('history.notSupport')" type="info" show-icon :closable="false"></el-alert> -->
                    <el-tab-pane :label="$t('history.ammter.gridOrSend')" name="ChargeAmmterPac">
                      <ammter-pac-detail-char v-loading="loading" :searchBtn="bntNumber" :charData="char.ammterDetail" :resizeName="char.activeAmmterName" />
                    </el-tab-pane>
                  </el-tabs>
                </div>
                <div class="col-lg-12" v-if="deviceTypeId == 5 && searchType == 'month'">
                  <el-alert :title="$t('history.notSupport')" type="info" show-icon :closable="false"></el-alert>
                </div>

                <div class="col-lg-12" v-if="deviceTypeId == 5 && searchType == 'year'">
                  <el-alert :title="$t('history.notSupport')" type="info" show-icon :closable="false"></el-alert>
                </div>

                <!-- 消防 日月年图表 -->
                <div class="col-lg-12" v-if="deviceTypeId == 6 && searchType == 'day'">
                  <el-alert :title="$t('history.notSupport')" type="info" show-icon :closable="false"></el-alert>
                </div>
                <div class="col-lg-12" v-if="deviceTypeId == 6 && searchType == 'month'">
                  <el-alert :title="$t('history.notSupport')" type="info" show-icon :closable="false"></el-alert>
                </div>

                <div class="col-lg-12" v-if="deviceTypeId == 6 && searchType == 'year'">
                  <el-alert :title="$t('history.notSupport')" type="info" show-icon :closable="false"></el-alert>
                </div>

                <div class="col-md-12  text-center" style="margin-top:5px;">
                  <el-button  v-if="!(deviceTypeId==5 && searchType== 'month')" type="aurora" :disabled="reportParam.isDis" :loading="reportParam.loading" class="is-active" size="small" @click="downPsReport()">{{ $t('ReportDownloads.title') }}</el-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- pcs分页 -->
      <div class="row clearfix" v-if="deviceTypeId == 1 && searchType == 'day'">
        <div class="col-md-12">
          <div class="card">
            <div class="body">
              <el-collapse accordion @change="handlePcsPageChange">
                <el-collapse-item>
                  <template slot="title">
                    <i class="header-icon el-icon-info mr-2"></i>
                    <!-- 查看更多明细 -->
                    {{ $t("history.move") }}
                  </template>
                  <div class="table-responsive table-md">
                    <!-- 未查询到相关数据 -->
                    <el-table :data="page.pcsDetail.rows" v-loading="loadingPage" :empty-text="$t('history.noData')" height="624">
                      <el-table-column :render-header="ChooseCircuit" :key="tablekey()">
                        <!-- 采集时间 -->
                        <el-table-column prop="getdatetime" :label="$t('history.pcs.time')" width="130">
                          <template slot-scope="scope">
                            <i class="el-icon-time"></i>
                            <span class="ml-1">{{
                              scope.row.getdatetime
                            }}</span>
                          </template>
                        </el-table-column>
                        <el-table-column label="DC">
                          <el-table-column label="1234" :render-header="TableDc" width="291">
                            <template slot-scope="props">
                              <div class="table-responsive table-DC">
                                <el-table :data="props.row.DC" :show-header="false" :row-class-name="tableRowClassName" height="69">
                                  <el-table-column prop="name" label="/" width="60" />
                                  <!-- 功率 -->
                                  <el-table-column prop="ppv" :label="$t('history.pcs.power')" :render-header="PowerKw" width="70" />
                                  <!-- 电流 -->
                                  <el-table-column prop="ipv" :label="$t('history.pcs.current')" :render-header="CurrentA" width="70" />
                                  <!-- 电压 -->
                                  <el-table-column prop="vpv" :label="$t('history.pcs.voltage')" :render-header="VoltageV" width="70" />
                                </el-table>
                              </div>
                            </template>
                          </el-table-column>
                        </el-table-column>
                        <el-table-column label="AC">
                          <el-table-column label="123" :render-header="TableAc" width="201">
                            <template slot-scope="props">
                              <div class="table-responsive table-DC">
                                <el-table :data="props.row.AC" :show-header="false" height="69">
                                  <el-table-column prop="name" label="/" width="40" style="color:#ff3300">
                                  </el-table-column>
                                  <!-- 电流 -->
                                  <el-table-column prop="Iac" :label="$t('history.pcs.current')" :render-header="CurrentA" width="70">
                                  </el-table-column>
                                  <!-- 电压 -->
                                  <el-table-column prop="Vac" :label="$t('history.pcs.voltage')" :render-header="VoltageV" width="70">
                                  </el-table-column>
                                </el-table>
                              </div>
                            </template>
                          </el-table-column>
                          <!-- 功率 -->
                          <el-table-column prop="pac" :label="$t('history.pcs.power')" :render-header="PowerKw" width="80" />
                          <!-- 频率 -->
                          <el-table-column prop="rFac" :label="$t('history.pcs.frequency')" :render-header="FrequencyHz" width="100" />
                        </el-table-column>
                        <!-- 模块温度 -->
                        <el-table-column prop="itemp" :label="$t('history.pcs.moduleItemp')" :render-header="PcsModelTemperatureOc" />
                        <!-- 环境温度 -->
                        <el-table-column prop="temp" :label="$t('history.pcs.temperature')" :render-header="PcsTemperatureOc" />
                        <!-- 今日发电 -->
                        <el-table-column prop="dayPac" :label="$t('history.pcs.dayTotalPac')" :render-header="DayPowerKwh" />
                        <!-- 累计发电 -->
                        <el-table-column prop="totalPac" :label="$t('history.pcs.totalPac')" :render-header="AllPowerKwh" />
                        <!-- 今日充电 -->
                        <el-table-column prop="dayInTotal" :label="$t('history.pcs.dayInTotalPac')" :render-header="DayChargeKwh" />
                        <!-- 累计充电 -->
                        <el-table-column prop="inTotal" :label="$t('history.pcs.inTotalPac')" :render-header="AllChargeKwh" />
                        <!-- 今日放电 -->
                        <el-table-column prop="dayOutTotal" :label="$t('history.pcs.dayOutTotalPac')" :render-header="DayDischargeKwh" />
                        <!-- 累计放电 -->
                        <el-table-column prop="outTotal" :label="$t('history.pcs.outTotalPac')" :render-header="AllDischargeKwh" />
                      </el-table-column>
                    </el-table>
                  </div>
                  <el-pagination v-if="deviceTypeId == 1 && searchType == 'day'" align="center" background layout="prev, pager, next" :total="page.pcsDetail.total" @current-change="handleCurrentChange" :page-size="page.pcsDetail.pageCount" :current-page.sync="page.pcsDetail.pageCurrent"> </el-pagination>
                </el-collapse-item>
              </el-collapse>
            </div>
          </div>
        </div>

      </div>

      <!-- bms 分页 -->
      <div class="row clearfix" v-if="deviceTypeId == 3 && searchType == 'day'">
        <div class="col-md-12">
          <div class="card">
            <div class="body">
              <el-collapse accordion @change="handleBmsPageChange">
                <el-collapse-item>
                  <template slot="title">
                    <i class="header-icon el-icon-info mr-2"></i>
                    <!-- 查看更多明细 -->
                    {{ $t("history.move") }}
                  </template>
                  <div class="table-responsive table-md">
                    <el-table :data="page.bmsDetail.rows" v-loading="loadingPage" :empty-text="$t('history.noData')" height="624">
                      <el-table-column :render-header="ChooseCircuitBMS" :key="tablekey()">
                        <!-- 采集时间="" -->
                        <el-table-column prop="getdatetime" :label="$t('history.pcs.time')" width="130">
                          <template slot-scope="scope">
                            <i class="el-icon-time"></i>
                            <span class="ml-1">{{
                              scope.row.getdatetime
                            }}</span>
                          </template>
                        </el-table-column>
                        <el-table-column prop="bv" label="电压" :render-header="VoltageV"></el-table-column>
                        <el-table-column prop="bcc" label="充/放电电流" :render-header="ChargeDischargeCurrentA"></el-table-column>
                        <el-table-column prop="bcp" label="充/放电功率" :render-header="ChargeInOutPower"></el-table-column>
                        <el-table-column prop="soc" label="%" :render-header="SocPercent"></el-table-column>
                        <!-- 电芯 -->
                        <el-table-column :label="$t('history.bms.time')">
                          <el-table-column label="123456" :render-header="TableBattery" width="550">
                            <template slot-scope="props">
                              <div class="table-responsive table-DC">
                                <el-table :data="props.row.tempBvs" :show-header="false" :row-class-name="tableRowClassName" height="69">
                                  <el-table-column prop="name" label="路数" width="80" />
                                  <el-table-column prop="maxTemp" label="最高温度" :render-header="HighTemperature" width="80" />
                                  <el-table-column prop="minTemp" label="最低温度" :render-header="LowTemperature" width="80" />
                                  <el-table-column prop="maxBv" label="最高电压" :render-header="HighVoltageV" width="80" />
                                  <el-table-column prop="minBv" label="最低电压" :render-header="LowVoltageV" width="80" />
                                  <el-table-column prop="mSoc" label="soc" :render-header="MSoc" width="80" />
                                </el-table>
                              </div>
                            </template>
                          </el-table-column>
                        </el-table-column>
                      </el-table-column>
                    </el-table>
                    <el-pagination v-if="deviceTypeId == 3 && searchType == 'day'" align="center" background layout="prev, pager, next" :total="page.bmsDetail.total" @current-change="handleBmsCurrentChange" :page-size="page.bmsDetail.pageCount" :current-page.sync="page.bmsDetail.pageCurrent"> </el-pagination>
                  </div>
                </el-collapse-item>
              </el-collapse>
            </div>
          </div>
        </div>
      </div>

      <!-- 空调 分页 -->
      <div class="row clearfix" v-if="deviceTypeId == 4 && searchType == 'day'">
        <div class="col-md-12">
          <div class="card">
            <div class="body">
              <el-collapse accordion @change="handleAriPageChange">
                <el-collapse-item>
                  <template slot="title">
                    <i class="header-icon el-icon-info mr-2"></i>
                    <!-- 查看更多明细 -->
                    {{ $t("history.move") }}
                  </template>
                  <div class="table-responsive table-md">
                    <el-table :data="page.airDetail.rows" v-loading="loadingPage" :empty-text="$t('history.noData')" height="624">
                      <!-- 空调 -->
                      <el-table-column :key="tablekey()" :label="$t('history.ari.name')">
                        <!-- 采集时间 -->
                        <el-table-column prop="getdatetime" :label="$t('history.pcs.time')" width="130">
                          <template slot-scope="scope">
                            <i class="el-icon-time"></i>
                            <span class="ml-1">{{
                              scope.row.getdatetime
                            }}</span>
                          </template>
                        </el-table-column>
                        <!-- label="状态" -->
                        <el-table-column prop="deviceFlag" :label="$t('history.ari.status')" />
                        <el-table-column prop="indoortemp" label="温度" :render-header="AriTemperatureOc" />
                      </el-table-column>
                    </el-table>
                    <el-pagination v-if="deviceTypeId == 4 && searchType == 'day'" align="center" background layout="prev, pager, next" :total="page.airDetail.total" @current-change="handleAriCurrentChange" :page-size="page.airDetail.pageCount" :current-page.sync="page.airDetail.pageCurrent"> </el-pagination>
                  </div>
                </el-collapse-item>
              </el-collapse>
            </div>
          </div>
        </div>
      </div>

      <!-- DCDC 分页 -->
      <div class="row clearfix" v-if="deviceTypeId == 2 && searchType == 'day'">
        <div class="col-md-12">
          <div class="card">
            <div class="body">
              <el-collapse accordion @change="handleDCDCPageChange">
                <el-collapse-item>
                  <template slot="title">
                    <i class="header-icon el-icon-info mr-2"></i>
                    <!-- 查看更多明细 -->
                    {{ $t("history.move") }}
                  </template>
                  <div class="table-responsive table-md">
                    <el-table :data="page.dcdcDetail.rows" v-loading="loadingPage" :empty-text="$t('history.noData')" height="624">
                      <el-table-column :key="tablekey()" label="DCDC">
                        <!-- 采集时间 -->
                        <el-table-column prop="getdatetime" :label="$t('history.pcs.time')" width="130">
                          <template slot-scope="scope">
                            <i class="el-icon-time"></i>
                            <span class="ml-1">{{
                              scope.row.getdatetime
                            }}</span>
                          </template>
                        </el-table-column>
                        <!-- 状态 -->
                        <el-table-column prop="deviceFlag" :label="$t('history.dcdc.status')" />
                        <el-table-column prop="ppv" label="功率" :render-header="PowerKw" />
                        <el-table-column prop="ipv" label="电流" :render-header="CurrentA" />
                        <el-table-column prop="vpv" label="电压" :render-header="VoltageV" />
                        <!-- 模块温度 -->
                        <el-table-column prop="itemp" :label="$t('history.dcdc.moduleItemp')" :render-header="DcdcModelTemperatureOc" />
                        <!-- 环境温度 -->
                        <el-table-column prop="temp" :label="$t('history.dcdc.temperature')" :render-header="DcdcTemperatureOc" />
                      </el-table-column>
                    </el-table>
                    <el-pagination v-if="deviceTypeId == 2 && searchType == 'day'" align="center" background layout="prev, pager, next" :total="page.dcdcDetail.total" @current-change="handleDcdcCurrentChange" :page-size="page.dcdcDetail.pageCount" :current-page.sync="page.dcdcDetail.pageCurrent"> </el-pagination>
                  </div>
                </el-collapse-item>
              </el-collapse>
            </div>
          </div>
        </div>
      </div>

      <!-- 电表 分页 -->
      <div class="row clearfix" v-if="deviceTypeId == 5 && searchType == 'day'">
        <div class="col-md-12">
          <div class="card">
            <div class="body">
              <el-collapse accordion @change="handleAmmeterPageChange">
                <el-collapse-item>
                  <template slot="title">
                    <i class="header-icon el-icon-info mr-2"></i>
                    <!-- 查看更多明细 -->
                    {{ $t("history.move") }}
                  </template>
                  <div class="table-responsive table-md">
                    <el-table :data="page.ammeterDetail.rows" v-loading="loadingPage" :empty-text="$t('history.noData')" height="624">
                      <!-- 电表 -->
                      <el-table-column :key="tablekey()" :label="$t('history.ammter.name')">
                        <!-- 采集时间 -->
                        <el-table-column prop="getdatetime" :label="$t('history.pcs.time')" width="130">
                          <template slot-scope="scope">
                            <i class="el-icon-time"></i>
                            <span class="ml-1">{{
                              scope.row.getdatetime
                            }}</span>
                          </template>
                        </el-table-column>
                        <!-- label="状态" -->
                        <el-table-column prop="pac" :label="$t('history.pcs.power')" :render-header="AmmeterTemperatureOc" />
                      </el-table-column>
                    </el-table>
                    <el-pagination v-if="deviceTypeId == 5 && searchType == 'day'" align="center" background layout="prev, pager, next" :total="page.ammeterDetail.total" @current-change="handleAmmeterCurrentChange" :page-size="page.ammeterDetail.pageCount" :current-page.sync="page.ammeterDetail.pageCurrent"> </el-pagination>
                  </div>
                </el-collapse-item>
              </el-collapse>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import t1 from "../assets/vendor/tscrollbar/TScrollbar-template";
import SelectPsIdView from "../components/HistoryView/SelectPsId.vue";

import GroupDeviceType from "../components/HistoryView/GroupDeviceType.vue";
import Devices from "../components/HistoryView/Devices.vue";
import SelectTime from "../components/HistoryView/SelectTime.vue";
import PcsPacDetailChar from "../components/HistoryView/PcsPacDetailChar.vue";
//import PcsVacDetailChar from "../components/HistoryView/PcsVacDetailChar.vue";
import PcsIacDetailChar from "../components/HistoryView/PcsIacDetailChar.vue";
import PcsTotalMonthChar from "../components/HistoryView/PcsTotalMonthChar.vue";
import PcsTotalChar from "../components/HistoryView/PcsTotalChar.vue";

import BmsBcpDetailChar from "../components/HistoryView/BmsBcpDetailChar.vue";
import BmsBvDetailChar from "../components/HistoryView/BmsBvDetailChar.vue";
import BmsBccDetailChar from "../components/HistoryView/BmsBccDetailChar.vue";
import BmsBtDetailChar from "../components/HistoryView/BmsBtDetailChar.vue";
// import BmsSocDetailChar from "../components/HistoryView/BmsSocDetailChar.vue";

import DcDcPpvDetailChar from "../components/HistoryView/DcDcPpvDetailChar.vue";
// import DcDcVpvDetailChar from "../components/HistoryView/DcDcVpvDetailChar.vue";
import DcDcIpvDetailChar from "../components/HistoryView/DcDcIpvDetailChar.vue";
import DcDcTotalMonthChar from "../components/HistoryView/DcDcTotalMonthChar.vue";
import DcDcTotalChar from "../components/HistoryView/DcDcTotalChar.vue";
import AirIndoorTempDetailChar from "../components/HistoryView/AirIndoorTempDetailChar.vue";

import BmsTotalMonthChar from "../components/HistoryView/BmsTotalMonthChar.vue";
import AirTotalMonthChar from "../components/HistoryView/AirTotalMonthChar.vue";
import AmmterPacDetailChar from "../components/HistoryView/AmmterPacDetailChar.vue";
import PcsTempDetailChar from "../components/HistoryView/PcsTempDetailChar.vue";
import DcdcTempDetailChar from "../components/HistoryView/DcdcTempDetailChar.vue";

$(function() {
  $('a[data-toggle="tab"]').on("show.bs.tab", function(e) {
    //获取已激活的标签页的名称
    var avtiveTab = $(e.target).text();
    //获取前一个激活的标签页的名称
    //alert(134);
  });
});

export default {
  components: {
    SelectPsIdView,
    GroupDeviceType,
    Devices,
    SelectTime,
    PcsPacDetailChar,
   // PcsVacDetailChar,
    PcsIacDetailChar,
    PcsTotalMonthChar,
    PcsIacDetailChar,
    BmsBcpDetailChar,
    BmsBvDetailChar,
    BmsBccDetailChar,
    BmsBtDetailChar,
   // BmsSocDetailChar,
    PcsTotalChar,
    DcDcPpvDetailChar,
   // DcDcVpvDetailChar,
    DcDcIpvDetailChar,
    DcDcTotalMonthChar,
    DcDcTotalChar,
    AirIndoorTempDetailChar,
    BmsTotalMonthChar,
    AirTotalMonthChar,
    AmmterPacDetailChar,
    PcsTempDetailChar,
    DcdcTempDetailChar
  },
  name: "History",

  data() {
    return {
      reportData:null,
      reportParam:{
        name:"",
        title:[],
        val:[],
        result:[],
        loading:false,
        isDis:true,
      },
      resetFlag:false,//切换电站、设备时重置
      bntNumber:"",
      loadingPage: false,
      loading: false,
      //选中的电站id
      psId: null,
      devPsId:null,
      buildDate: null,
      checkedDeviceTypeId: null,
      //电站列表
      psList: [],

      deviceTypeList: [],
      deviceList: [],
      deviceId: null,

      deviceTypeId: null,

      //查询时间
      searchDate: null,
      //查询类型
      searchType: null,

      otimer: null,
      isload: false,
      st: { opacity: 1 },
      page: {
        activePageName: false,
        pcsDetail: {
          total: 0,
          pageCount: 10,
          rows: [],
          pageCurrent:1
        },

        bmsDetail: {
          total: 0,
          pageCount: 10,
          rows: [],
          pageCurrent:1
        },
        airDetail: {
          total: 0,
          pageCount: 10,
          rows: [],
          pageCurrent:1
        },
        dcdcDetail: {
          total: 0,
          pageCount: 10,
          rows: [],
          pageCurrent:1
        },
        ammeterDetail: {
          total: 0,
          pageCount: 10,
          rows: [],
          pageCurrent:1
        },
      },
      char: {
        activeName: "ChargePcsPac",
        activePcsDayName: "ChargePcsDays",
        activePcsMonthName: "ChargePcsMonths",
        activeBmsName: "ChargeBmsBcp",
        activeDcDcName: "ChargeDcDcPpv",
        activeAirName: "ChargeAirTemp",
        activeDcDcDayName: "ChargeDcDcDays",
        activeDcDcMonthName: "ChargeDcDcMonths",
        activeAmmterName:"ChargeAmmterPac",
        pcsDetail: [],
        pcsDays: [],
        pcsMonths: [],

        bmsDetail: [],
        bmsDays:[],

        dcdcDetail: [],
        dcdcDays: [],
        dcdcMonths: [],
        airDetail: [],
        airDays:[],
        ammterDetail:[],
      },

      isDatepicker: true,
      DCNum: 3,
    };
  },
  watch: {
    '$store.state.languageId': function () {
        this.formatReport(this.reportData)
        this.bntNumber = Math.floor(Math.random() * 999999);
    }
  },
  mounted() {
       //通过路由获取传值的deviceId和deviceTypeId;
       if(this.$route.params.deviceId !=undefined && this.$route.params.deviceId != ""){
         window.sessionStorage.setItem("seldeviceId", this.$route.params.deviceId);
         window.sessionStorage.setItem("seldevicetypeid", this.$route.params.deviceTypeId)
      }
    // this.init();
    // this.getPsInfos();
  },
  methods: {
    addBmsPara(){
                var _this = this;
                var deviceInfo=JSON.parse(window.sessionStorage.getItem("deviceInfo"));
                var deviceModule=deviceInfo.deviceModule|| 0
                  var mtMaxList=[]
                  var mtMinList=[]
                  var mvMaxList=[]
                  var mvMinList=[]
                  var msocList=[]
                  var mtMaxListVal=[]
                  var mtMinListVal=[]
                  var mvMaxListVal=[]
                  var mvMinListVal=[]
                  var msocListVal=[]
                  var vpvsVal=[]
                  for(var i=1;i<=deviceModule;i++){
                      mtMaxList.push("RACK"+i+this.$t("ReportDownloads.device.zgwd") )
                      mtMinList.push("RACK"+i+this.$t("ReportDownloads.device.zdwd"))
                      mvMaxList.push("RACK"+i+this.$t("ReportDownloads.device.zgdy"))
                      mvMinList.push("RACK"+i+this.$t("ReportDownloads.device.zddy"))
                       msocList.push("RACK"+i+"Soc(%)" )
                      mtMaxListVal.push("mtMaxList-"+ (i-1))
                      mtMinListVal.push("mtMinList-"+ (i-1))
                      mvMaxListVal.push("mvMaxList-"+ (i-1))
                      mvMinListVal.push("mvMinList-"+ (i-1))
                       msocListVal.push("msocList-"+ (i-1))
                  }
                  _this.reportParam.title = _this.reportParam.title.concat(mtMaxList)
                  _this.reportParam.title = _this.reportParam.title.concat(mtMinList)
                  _this.reportParam.title = _this.reportParam.title.concat(mvMaxList)
                  _this.reportParam.title = _this.reportParam.title.concat(mvMinList)
                  _this.reportParam.title = _this.reportParam.title.concat(msocList)
                  _this.reportParam.val = _this.reportParam.val.concat(mtMaxListVal)
                  _this.reportParam.val = _this.reportParam.val.concat(mtMinListVal)
                  _this.reportParam.val = _this.reportParam.val.concat(mvMaxListVal)
                  _this.reportParam.val = _this.reportParam.val.concat(mvMinListVal)
                  _this.reportParam.val = _this.reportParam.val.concat(msocListVal)
    },
    formatReport(result){
      
      var _this = this;
      _this.reportParam = {
        name:"",
        title:[],
        val:[],
        result:[],
        loading:false,
        isDis:true,
      }
      _this.reportParam.result=result
      if(_this.reportParam.result==null || _this.reportParam.result.length==0){
         _this.reportParam.isDis=true
      }else{
         _this.reportParam.isDis=false
      }
      if(_this.searchType=="day" ){
       
         //1.pcs  2.dcdc  3.Bms   4.空调   5.电表
          if(_this.$store.state.psTypeId==1){
            //自家消费
            switch (_this.deviceTypeId) {
              case 1:
                //pcs
                 var deviceInfo=JSON.parse(window.sessionStorage.getItem("deviceInfo"));
                 var deviceModule=deviceInfo.deviceModule|| 0
                  _this.reportParam.title=[this.$t("ReportDownloads.getdatetime"),this.$t("ReportDownloads.deviceName"),this.$t("ReportDownloads.device.jldl2")+"A(A)",this.$t("ReportDownloads.device.jldl2")+"B(A)",this.$t("ReportDownloads.device.jldl2")+"C(A)",this.$t("ReportDownloads.device.jldy2")+"A(V)",this.$t("ReportDownloads.device.jldy2")+"B(V)",this.$t("ReportDownloads.device.jldy2")+"C(V)",this.$t("ReportDownloads.device.jlgl"),this.$t("ReportDownloads.device.jlpl"),this.$t("ReportDownloads.device.glmkwd"),this.$t("ReportDownloads.device.jgwd"),this.$t("ReportDownloads.device.jrfd"),this.$t("ReportDownloads.device.ljfd"),this.$t("ReportDownloads.device.jrcd"),this.$t("ReportDownloads.device.ljcd"),this.$t("ReportDownloads.device.jrfd2"),this.$t("ReportDownloads.device.ljfd2")]
                  _this.reportParam.val=['getdatetime','deviceName','rIac','sIac','tIac','rVac','sVac','tVac','pac','rFac','itemp','temp','dayPac','totalPac','dayInTotal','inTotal','dayOutTotal','outTotal']
                  var ipvs=[]
                  var ppvs=[]
                  var vpvs=[]
                  var ipvsVal=[]
                  var ppvsVal=[]
                  var vpvsVal=[]
                  for(var i=1;i<=deviceModule;i++){
                      ipvs.push(this.$t("ReportDownloads.device.zlglpv") + i + "（kW）")
                      ppvs.push(this.$t("ReportDownloads.device.zldlpv") + i + "（A）")
                      vpvs.push(this.$t("ReportDownloads.device.zldypv") + i + "（V）")
                      ipvsVal.push("ppvs-"+ (i-1))
                      ppvsVal.push("ipvs-"+ (i-1))
                      vpvsVal.push("vpvs-"+ (i-1))
                  }
                  _this.reportParam.title = _this.reportParam.title.concat(ipvs)
                  _this.reportParam.title = _this.reportParam.title.concat(ppvs)
                  _this.reportParam.title = _this.reportParam.title.concat(vpvs)

                    _this.reportParam.val = _this.reportParam.val.concat(ipvsVal)
                  _this.reportParam.val = _this.reportParam.val.concat(ppvsVal)
                  _this.reportParam.val = _this.reportParam.val.concat(vpvsVal)

                  _this.reportParam.name="PCS--"+window.sessionStorage.getItem("psName") + this.$moment(this.searchDate).format("YYYY-MM-DD")+ this.$moment().format(" HHmmss");
                break
              case 2:
                //dcdc
                _this.reportParam.title=[this.$t("ReportDownloads.getdatetime"),this.$t("ReportDownloads.deviceName"),this.$t("ReportDownloads.device.zt"),this.$t("ReportDownloads.device.gl"),this.$t("ReportDownloads.device.dl"),this.$t("ReportDownloads.device.dy"),this.$t("ReportDownloads.device.glmkwd"),this.$t("ReportDownloads.device.jgwd")]
                _this.reportParam.val=['getdatetime','deviceName','deviceFlag','ppv','ipv','vpv','itemp','temp']
                 _this.reportParam.name="DCDC--"+window.sessionStorage.getItem("psName") + this.$moment(this.searchDate).format("YYYY-MM-DD")+ this.$moment().format(" HHmmss");
                break;
              case 3:
                //Bms
                _this.reportParam.title=[this.$t("ReportDownloads.getdatetime"),this.$t("ReportDownloads.deviceName"),this.$t("ReportDownloads.device.dy"),this.$t("ReportDownloads.device.cfddl"),this.$t("ReportDownloads.device.cfdgl"),'SOC（%）']
                _this.reportParam.val=['getdatetime','deviceName','bv','bcc','bcp','soc']
                _this.addBmsPara();
                _this.reportParam.name="BMS--"+window.sessionStorage.getItem("psName") + this.$moment(this.searchDate).format("YYYY-MM-DD")+ this.$moment().format(" HHmmss");
                break;
              case 4:
                //空调
                _this.reportParam.title=[this.$t("ReportDownloads.getdatetime"),this.$t("ReportDownloads.deviceName"),this.$t("ReportDownloads.device.zt"),this.$t("ReportDownloads.device.snwd")]
                _this.reportParam.val=['getdatetime','deviceName','deviceFlag','indoortemp']
                _this.reportParam.name=this.$t("ReportDownloads.device.kt")+window.sessionStorage.getItem("psName") + this.$moment(this.searchDate).format("YYYY-MM-DD")+ this.$moment().format(" HHmmss");
                break;
              case 5:
                //电表
                _this.reportParam.title=[this.$t("ReportDownloads.getdatetime"),this.$t("ReportDownloads.deviceName"),this.$t("ReportDownloads.device.gl")]
                _this.reportParam.val=['getdatetime','deviceName','pac']
                _this.reportParam.name=this.$t("ReportDownloads.device.db")+window.sessionStorage.getItem("psName") + this.$moment(this.searchDate).format("YYYY-MM-DD")+ this.$moment().format(" HHmmss");
                break;
              default:
              _this.reportParam.isDis = true;
              break;
            }  
          }else if(_this.$store.state.psTypeId==2){
              //过激载
               switch (_this.deviceTypeId) {
              case 1:
                //pcs
                 var deviceInfo=JSON.parse(window.sessionStorage.getItem("deviceInfo"));
                 var deviceModule=deviceInfo.deviceModule|| 0
                  _this.reportParam.title=[this.$t("ReportDownloads.getdatetime"),this.$t("ReportDownloads.deviceName"),this.$t("ReportDownloads.device.jldl2")+"A(A)",this.$t("ReportDownloads.device.jldl2")+"B(A)",this.$t("ReportDownloads.device.jldl2")+"C(A)",this.$t("ReportDownloads.device.jldy2")+"A(V)",this.$t("ReportDownloads.device.jldy2")+"B(V)",this.$t("ReportDownloads.device.jldy2")+"C(V)",this.$t("ReportDownloads.device.jlgl"),this.$t("ReportDownloads.device.jlpl"),this.$t("ReportDownloads.device.glmkwd"),this.$t("ReportDownloads.device.jgwd"),this.$t("ReportDownloads.device.jrfd"),this.$t("ReportDownloads.device.ljfd"),this.$t("ReportDownloads.device.jrcd"),this.$t("ReportDownloads.device.ljcd"),this.$t("ReportDownloads.device.jrfd2"),this.$t("ReportDownloads.device.ljfd2")]
                  _this.reportParam.val=['getdatetime','deviceName','rIac','sIac','tIac','rVac','sVac','tVac','pac','rFac','itemp','temp','dayPac','totalPac','dayInTotal','inTotal','dayOutTotal','outTotal']
                  var deviceModule=deviceInfo.deviceModule|| 0
                  var ipvs=[]
                  var ppvs=[]
                  var vpvs=[]
                  var ipvsVal=[]
                  var ppvsVal=[]
                  var vpvsVal=[]
                  for(var i=1;i<=deviceModule;i++){
                      ipvs.push(this.$t("ReportDownloads.device.zlglpv") + i + "（kW）")
                      ppvs.push(this.$t("ReportDownloads.device.zldlpv") + i + "（A）")
                      vpvs.push(this.$t("ReportDownloads.device.zldypv") + i + "（V）")
                      ipvsVal.push("ppvs-"+ (i-1))
                      ppvsVal.push("ipvs-"+ (i-1))
                      vpvsVal.push("vpvs-"+ (i-1))
                  }
                  _this.reportParam.title = _this.reportParam.title.concat(ipvs)
                  _this.reportParam.title = _this.reportParam.title.concat(ppvs)
                  _this.reportParam.title = _this.reportParam.title.concat(vpvs)

                    _this.reportParam.val = _this.reportParam.val.concat(ipvsVal)
                  _this.reportParam.val = _this.reportParam.val.concat(ppvsVal)
                  _this.reportParam.val = _this.reportParam.val.concat(vpvsVal)

                  _this.reportParam.name="PCS--"+window.sessionStorage.getItem("psName") + this.$moment(this.searchDate).format("YYYY-MM-DD")+ this.$moment().format(" HHmmss");
                break
              case 2:
                //dcdc
                _this.reportParam.title=[this.$t("ReportDownloads.getdatetime"),this.$t("ReportDownloads.deviceName"),this.$t("ReportDownloads.device.zt"),this.$t("ReportDownloads.device.gl"),this.$t("ReportDownloads.device.dl"),this.$t("ReportDownloads.device.dy"),this.$t("ReportDownloads.device.glmkwd"),this.$t("ReportDownloads.device.jgwd")]
                _this.reportParam.val=['getdatetime','deviceName','deviceFlag','ppv','ipv','vpv','itemp','temp']
                 _this.reportParam.name="DCDC--"+window.sessionStorage.getItem("psName") + this.$moment(this.searchDate).format("YYYY-MM-DD")+ this.$moment().format(" HHmmss");
                break;
              case 3:
                //Bms
                _this.reportParam.title=[this.$t("ReportDownloads.getdatetime"),this.$t("ReportDownloads.deviceName"),this.$t("ReportDownloads.device.dy"),this.$t("ReportDownloads.device.cfddl"),this.$t("ReportDownloads.device.cfdgl"),'SOC（%）']
                _this.reportParam.val=['getdatetime','deviceName','bv','bcc','bcp','soc']
                _this.addBmsPara();
                _this.reportParam.name="BMS--"+window.sessionStorage.getItem("psName") + this.$moment(this.searchDate).format("YYYY-MM-DD")+ this.$moment().format(" HHmmss");
                break;
              case 4:
                //空调
                _this.reportParam.title=[this.$t("ReportDownloads.getdatetime"),this.$t("ReportDownloads.deviceName"),this.$t("ReportDownloads.device.zt"),this.$t("ReportDownloads.device.snwd")]
                _this.reportParam.val=['getdatetime','deviceName','deviceFlag','indoortemp']
                _this.reportParam.name=this.$t("ReportDownloads.device.kt")+window.sessionStorage.getItem("psName") + this.$moment(this.searchDate).format("YYYY-MM-DD")+ this.$moment().format(" HHmmss");
                break;
              default:
              _this.reportParam.isDis = true;
              break;  
           
            }  
          }else if(_this.$store.state.psTypeId==3){
              //削峰（AC）
               switch (_this.deviceTypeId) {
              case 1:
                //pcs
                 var deviceInfo=JSON.parse(window.sessionStorage.getItem("deviceInfo"));
                 var deviceModule=deviceInfo.deviceModule|| 0
                  _this.reportParam.title=[this.$t("ReportDownloads.getdatetime"),this.$t("ReportDownloads.deviceName"),this.$t("ReportDownloads.device.jldl2")+"A(A)",this.$t("ReportDownloads.device.jldl2")+"B(A)",this.$t("ReportDownloads.device.jldl2")+"C(A)",this.$t("ReportDownloads.device.jldy2")+"A(V)",this.$t("ReportDownloads.device.jldy2")+"B(V)",this.$t("ReportDownloads.device.jldy2")+"C(V)",this.$t("ReportDownloads.device.jlgl"),this.$t("ReportDownloads.device.jlpl"),this.$t("ReportDownloads.device.glmkwd"),this.$t("ReportDownloads.device.jgwd"),this.$t("ReportDownloads.device.jrfd"),this.$t("ReportDownloads.device.ljfd"),this.$t("ReportDownloads.device.jrcd"),this.$t("ReportDownloads.device.ljcd"),this.$t("ReportDownloads.device.jrfd2"),this.$t("ReportDownloads.device.ljfd2")]
                  _this.reportParam.val=['getdatetime','deviceName','rIac','sIac','tIac','rVac','sVac','tVac','pac','rFac','itemp','temp','dayPac','totalPac','dayInTotal','inTotal','dayOutTotal','outTotal']
                  var ipvs=[]
                  var ppvs=[]
                  var vpvs=[]
                  var ipvsVal=[]
                  var ppvsVal=[]
                  var vpvsVal=[]
                  for(var i=1;i<=deviceModule;i++){
                      ipvs.push(this.$t("ReportDownloads.device.zlglpv") + i + "（kW）")
                      ppvs.push(this.$t("ReportDownloads.device.zldlpv") + i + "（A）")
                      vpvs.push(this.$t("ReportDownloads.device.zldypv") + i + "（V）")
                      ipvsVal.push("ppvs-"+ (i-1))
                      ppvsVal.push("ipvs-"+ (i-1))
                      vpvsVal.push("vpvs-"+ (i-1))
                  }
                  _this.reportParam.title = _this.reportParam.title.concat(ipvs)
                  _this.reportParam.title = _this.reportParam.title.concat(ppvs)
                  _this.reportParam.title = _this.reportParam.title.concat(vpvs)

                    _this.reportParam.val = _this.reportParam.val.concat(ipvsVal)
                  _this.reportParam.val = _this.reportParam.val.concat(ppvsVal)
                  _this.reportParam.val = _this.reportParam.val.concat(vpvsVal)

                  _this.reportParam.name="PCS--"+window.sessionStorage.getItem("psName") + this.$moment(this.searchDate).format("YYYY-MM-DD")+ this.$moment().format(" HHmmss");
                break
              case 3:
                //Bms
                _this.reportParam.title=[this.$t("ReportDownloads.getdatetime"),this.$t("ReportDownloads.deviceName"),this.$t("ReportDownloads.device.dy"),this.$t("ReportDownloads.device.cfddl"),this.$t("ReportDownloads.device.cfdgl"),'SOC（%）']
                _this.reportParam.val=['getdatetime','deviceName','bv','bcc','bcp','soc']
                _this.addBmsPara();
                _this.reportParam.name="BMS--"+window.sessionStorage.getItem("psName") + this.$moment(this.searchDate).format("YYYY-MM-DD")+ this.$moment().format(" HHmmss");
                break;
              case 4:
                //空调
                _this.reportParam.title=[this.$t("ReportDownloads.getdatetime"),this.$t("ReportDownloads.deviceName"),this.$t("ReportDownloads.device.zt"),this.$t("ReportDownloads.device.snwd")]
                _this.reportParam.val=['getdatetime','deviceName','deviceFlag','indoortemp']
                _this.reportParam.name=this.$t("ReportDownloads.device.kt")+window.sessionStorage.getItem("psName") + this.$moment(this.searchDate).format("YYYY-MM-DD")+ this.$moment().format(" HHmmss");
                break;
              default:
              _this.reportParam.isDis = true;
              break;  
            }  
          }   
          

      }else if(_this.searchType=="month"){

         if(_this.$store.state.psTypeId==1){
           //1.pcs  2.dcdc  3.Bms   4.空调   5.电表
            switch (_this.deviceTypeId) {
              case 1:
                //pcs
                  _this.reportParam.title=[this.$t("ReportDownloads.getdatetime"),this.$t("ReportDownloads.deviceName"),this.$t("ReportDownloads.device.cdl"),this.$t("ReportDownloads.device.fdl2")]
                  _this.reportParam.val=['getdatetime','deviceName','inTotal','outTotal']
                  _this.reportParam.name="PCS--"+window.sessionStorage.getItem("psName") + this.$moment(this.searchDate).format("YYYY-MM")+ this.$moment().format(" HHmmss");
                break
              case 2:
                //dcdc
                _this.reportParam.title=[this.$t("ReportDownloads.getdatetime"),this.$t("ReportDownloads.deviceName"),this.$t("ReportDownloads.device.gffdl")]
                _this.reportParam.val=['getdatetime','deviceName','pvTotal']
                _this.reportParam.name="DCDC--"+window.sessionStorage.getItem("psName") + this.$moment(this.searchDate).format("YYYY-MM")+ this.$moment().format(" HHmmss");
                break;
              case 3:
                //Bms
                _this.reportParam.title=[this.$t("ReportDownloads.getdatetime"),this.$t("ReportDownloads.deviceName"),this.$t("ReportDownloads.device.zgwd"),this.$t("ReportDownloads.device.zdwd"),this.$t("ReportDownloads.device.zgdy"),this.$t("ReportDownloads.device.zddy")]
                _this.reportParam.val=['getdatetime','deviceName','btmax','btmin','bvmax','bvmin']
                _this.reportParam.name="BMS--"+window.sessionStorage.getItem("psName") + this.$moment(this.searchDate).format("YYYY-MM")+ this.$moment().format(" HHmmss");
                break;
              case 4:
                //空调
                _this.reportParam.title=[this.$t("ReportDownloads.getdatetime"),this.$t("ReportDownloads.deviceName"),this.$t("ReportDownloads.device.zgwd"),this.$t("ReportDownloads.device.zdwd")]
                _this.reportParam.val=['getdatetime','deviceName','maxindoortemp','minindoortemp']
                _this.reportParam.name=this.$t("ReportDownloads.device.kt")+window.sessionStorage.getItem("psName") + this.$moment(this.searchDate).format("YYYY-MM")+ this.$moment().format(" HHmmss");
                break;
              default:
              _this.reportParam.isDis = true;
              break;
            }  
          }else if(_this.$store.state.psTypeId==2){
              //过激载
              switch (_this.deviceTypeId) {
                case 1:
                  //pcs
                    _this.reportParam.title=[this.$t("ReportDownloads.getdatetime"),this.$t("ReportDownloads.deviceName"),this.$t("ReportDownloads.device.fdl")]
                    _this.reportParam.val=['getdatetime','deviceName','dayPac']
                    _this.reportParam.name="PCS--"+window.sessionStorage.getItem("psName") + this.$moment(this.searchDate).format("YYYY-MM")+ this.$moment().format(" HHmmss");
                  break
                case 2:
                    //dcdc
                    _this.reportParam.title=[this.$t("ReportDownloads.getdatetime"),this.$t("ReportDownloads.deviceName"),this.$t("ReportDownloads.device.gffdl")]
                    _this.reportParam.val=['getdatetime','deviceName','pvTotal']
                    _this.reportParam.name="DCDC--"+window.sessionStorage.getItem("psName") + this.$moment(this.searchDate).format("YYYY-MM")+ this.$moment().format(" HHmmss");
                  break;
                case 3:
                  //Bms
                  _this.reportParam.title=[this.$t("ReportDownloads.getdatetime"),this.$t("ReportDownloads.deviceName"),this.$t("ReportDownloads.device.zgwd"),this.$t("ReportDownloads.device.zdwd"),this.$t("ReportDownloads.device.zgdy"),this.$t("ReportDownloads.device.zddy")]
                  _this.reportParam.val=['getdatetime','deviceName','btmax','btmin','bvmax','bvmin']
                  _this.reportParam.name="BMS--"+window.sessionStorage.getItem("psName") + this.$moment(this.searchDate).format("YYYY-MM")+ this.$moment().format(" HHmmss");
                  break;
                case 4:
                  //空调
                  _this.reportParam.title=[this.$t("ReportDownloads.getdatetime"),this.$t("ReportDownloads.deviceName"),this.$t("ReportDownloads.device.zgwd"),this.$t("ReportDownloads.device.zdwd")]
                  _this.reportParam.val=['getdatetime','deviceName','maxindoortemp','minindoortemp']
                  _this.reportParam.name=this.$t("ReportDownloads.device.kt")+window.sessionStorage.getItem("psName") + this.$moment(this.searchDate).format("YYYY-MM")+ this.$moment().format(" HHmmss");
                  break;
                  default:
              _this.reportParam.isDis = true;
              break;
              }  

              
          }else if(_this.$store.state.psTypeId==3){
              //削峰（AC）
                //过激载
              switch (_this.deviceTypeId) {
                case 1:
                  //pcs
                    _this.reportParam.title=[this.$t("ReportDownloads.getdatetime"),this.$t("ReportDownloads.deviceName"),this.$t("ReportDownloads.device.cdl"),this.$t("ReportDownloads.device.fdl2")]
                    _this.reportParam.val=['getdatetime','deviceName','inTotal','outTotal']
                    _this.reportParam.name="PCS--"+window.sessionStorage.getItem("psName") + this.$moment(this.searchDate).format("YYYY-MM")+ this.$moment().format(" HHmmss");
                  break
                case 3:
                  //Bms
                  _this.reportParam.title=[this.$t("ReportDownloads.getdatetime"),this.$t("ReportDownloads.deviceName"),this.$t("ReportDownloads.device.zgwd"),this.$t("ReportDownloads.device.zdwd"),this.$t("ReportDownloads.device.zgdy"),this.$t("ReportDownloads.device.zddy")]
                  _this.reportParam.val=['getdatetime','deviceName','btmax','btmin','bvmax','bvmin']
                  _this.reportParam.name="BMS--"+window.sessionStorage.getItem("psName") + this.$moment(this.searchDate).format("YYYY-MM")+ this.$moment().format(" HHmmss");
                  break;
                case 4:
                  //空调
                  _this.reportParam.title=[this.$t("ReportDownloads.getdatetime"),this.$t("ReportDownloads.deviceName"),this.$t("ReportDownloads.device.zgwd"),this.$t("ReportDownloads.device.zdwd")]
                  _this.reportParam.val=['getdatetime','deviceName','maxindoortemp','minindoortemp']
                  _this.reportParam.name=this.$t("ReportDownloads.device.kt")+window.sessionStorage.getItem("psName") + this.$moment(this.searchDate).format("YYYY-MM")+ this.$moment().format(" HHmmss");
                  break;
                default:
              _this.reportParam.isDis = true;
              break;
          }   
       
        }
      }
    },
     //电站报表下载
    formatJson(filterVal, jsonData) {
      var _this=this;
      return jsonData.map(v => filterVal.map(j => {
        if (j === 'getdatetime') {
          if(_this.searchType=="month" ){
              v[j]= v[j]!=null?v[j].split(' ')[0]:null
              return v[j]!=null?v[j].split('-')[2]:'-'
          }else {
              return v[j]
          }
          
        }
        else if(j === 'deviceName'){
          var deviceInfo=JSON.parse(window.sessionStorage.getItem("deviceInfo"));
          return deviceInfo.deviceName
        }
        else if(j.indexOf("mtMaxList") !=-1 || j.indexOf("mtMinList") !=-1 || j.indexOf("mvMaxList") !=-1 || j.indexOf("mvMinList") !=-1 || j.indexOf("msocList") !=-1){
          
          return v[j.split('-')[0]][j.split('-')[1]]

        }
        else if(j.indexOf("ipvs") !=-1 || j.indexOf("ppvs") !=-1 || j.indexOf("vpvs") !=-1){
          
          return v[j.split('-')[0]][j.split('-')[1]]

        }
        else if(j === 'deviceFlag'){
              switch (v[j]) {
                case 0:
                  return  this.$t("history.ari.normal"); //"正常"
                  break;
                case 1:
                  return this.$t("history.ari.offLine"); //"离线"
                  break;
                case 3:
                  return this.$t("home.shutDown"); //"停机"
                  break;  
                case 4:
                  return this.$t("history.ari.fault"); //"故障"
                  break;
                default:
                  return "-";
                  break;
              }
        }
        else {
          
              return v[j]
        }
        
      }))
    },
    downPsReport(){
      
      var _this=this;
        _this.reportParam.loading=true
        import('@/assets/vendor/excel/Export2Excel').then(excel => {
          const tHeader = _this.reportParam.title
          const filterVal = _this.reportParam.val
          const list = _this.reportParam.result
          if(list!=null && list.length > 0){
            const data = _this.formatJson(filterVal, list)
            //加统计
            if(_this.deviceTypeId!=4 && _this.deviceTypeId!=3){
              var total= JSON.parse(JSON.stringify(data[0])) 
              total[0]=this.$t("ReportDownloads.total")
              total[1]="";
              if(_this.searchType=="month" ){
                  $(data).each(function(i,val){
                    if(i>0){
                      $(val).each(function(x,t){
                        if(x>1){
                          total[x]+=t
                        }
                      })
                    }
                  })
                  data.push(total);
              }
            }
            excel.export_json_to_excel({
              header: tHeader,
              data,
              filename:_this.reportParam.name,
              bookType: 'csv'
            })
          }
           _this.reportParam.loading=false
        })
    },
    //合并列
    arraySpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 1) {
        return [1, 4];
      }
      if (columnIndex === 2) {
        return [1, 3];
      }
    },

    //表头参数名
    /* 功率 */
    PowerKw(h) {
      return (
        // 功率
        <div>
          <span>{this.$t("history.pcs.power")}</span>
          <span class="kw small ml-1"></span>
        </div>
      );
    },
    /* 电流 */
    CurrentA(h) {
      return (
        // 电流
        <div>
          <span>{this.$t("history.pcs.current")}</span>
          <span class="A small ml-1"></span>
        </div>
      );
    },
    /* 电压 */
    VoltageV(h) {
      return (
        // 电压
        <div>
          <span>{this.$t("history.pcs.voltage")}</span>
          <span class="V small ml-1"></span>
        </div>
      );
    },
    /* 赫兹 */
    FrequencyHz(h) {
      return (
        // 频率
        <div>
          <span>{this.$t("history.pcs.frequency")}</span>
          <span class="hz small ml-1"></span>
        </div>
      );
    },
    /* pcs模块温度 */
    PcsModelTemperatureOc(h) {
      return (
        // 温度
        <div>
          <span>{this.$t("history.pcs.moduleItemp")}</span>
          <span class="oc small ml-1"></span>
        </div>
      );
    },
    /* pcs环境温度 */
    PcsTemperatureOc(h) {
      return (
        // 温度
        <div>
          <span>{this.$t("history.pcs.temperature")}</span>
          <span class="oc small ml-1"></span>
        </div>
      );
    },
    /* 今日发电 */
    DayPowerKwh(h) {
      return (
        // 今日发电
        <div>
          <span>{this.$t("history.pcs.dayTotalPac")}</span>
          <span class="kwh small ml-1"></span>
        </div>
      );
    },
    /* 累计发电 */
    AllPowerKwh(h) {
      return (
        // 累计发电
        <div>
          <span>{this.$t("history.pcs.totalPac")}</span>
          <span class="kwh small ml-1"></span>
        </div>
      );
    },
    /* 今日充电 */
    DayChargeKwh(h) {
      return (
        // 今日充电
        <div>
          <span>{this.$t("history.pcs.dayInTotalPac")}</span>
          <span class="kwh small ml-1"></span>
        </div>
      );
    },
    /* 累计充电 */
    AllChargeKwh(h) {
      return (
        // 累计充电
        <div>
          <span>{this.$t("history.pcs.inTotalPac")}</span>
          <span class="kwh small ml-1"></span>
        </div>
      );
    },
    /* 今日放电 */
    DayDischargeKwh(h) {
      return (
        //今日放电
        <div>
          <span>{this.$t("history.pcs.dayOutTotalPac")}</span>
          <span class="kwh small ml-1"></span>
        </div>
      );
    },
    /* 累计发电 */
    AllDischargeKwh(h) {
      return (
        // 累计放电
        <div>
          <span>{this.$t("history.pcs.outTotalPac")}</span>
          <span class="kwh small ml-1"></span>
        </div>
      );
    },
    /* 最高温度 */
    HighTemperature(h) {
      return (
        // 最高温度
        <div>
          <span>{this.$t("history.bms.maxTemp")}</span>
          <br />
          <span class="oc small ml-1"></span>
        </div>
      );
    },
    /* 最低温度 */
    LowTemperature(h) {
      return (
        // 最低温度
        <div>
          <span>{this.$t("history.bms.minTemp")}</span>
          <span class="oc small ml-1"></span>
        </div>
      );
    },
    /* 最高电压 */
    HighVoltageV(h) {
      return (
        // 最高电压
        <div>
          <span>{this.$t("history.bms.maxVoltage")}</span>
          <span class="V small ml-1"></span>
        </div>
      );
    },
    /* 最低电压 */
    LowVoltageV(h) {
      return (
        // 最低电压
        <div>
          <span>{this.$t("history.bms.minVoltage")}</span>
          <span class="V small ml-1"></span>
        </div>
      );
    },
    /* SOC */
    MSoc(h) {
      return (
        // SOC
        <div>
          <span>SOC</span>
          <span class="V small ml-1"></span>
        </div>
      );
    },
    /* 充/放电电流 */
    ChargeDischargeCurrentA(h) {
      return (
        // 充/放电电流
        <div>
          <span>{this.$t("history.bms.inOutCurrent")}</span>
          <span class="A small ml-1"></span>
        </div>
      );
    },
    /* 充/放电电压 */
    ChargeDischargeVoltageV(h) {
      return (
        // 充/放电电压
        <div>
          <span>{this.$t("history.bms.inOutVoltage")}</span>
          <span class="A small ml-1"></span>
        </div>
      );
    },

    /* 充/放电功率 */
    ChargeInOutPower(h) {
      return (
        // 充/放电功率
        <div>
          <span>{this.$t("history.bms.char.inOutPower")}</span>
          <span class="kw small ml-1"></span>
        </div>
      );
    },
    /* SOC */
    SocPercent(h) {
      return (
        // SOC
        <div>
          <span>{this.$t("history.bms.soc")}</span>
          <span class="percent small ml-1"></span>
        </div>
      );
    },

    /* PCS */
    Pcs(h) {
      return <i class="fad fa-3x fa-archive"></i>;
    },
    /* DC表头 */
    TableDc(h) {
      return (
        <table>
          <thead>
            <tr style="background:#f5f7fa">
              <th width="60">/</th>

              <th width="70">
                {this.$t("history.pcs.power")} <span class="kw"></span>
              </th>

              <th width="70">
                {this.$t("history.pcs.current")}
                <span class="A"></span>
              </th>

              <th width="70">
                {this.$t("history.pcs.voltage")}
                <span class="V"></span>
              </th>
            </tr>
          </thead>
        </table>
      );
    },
    /* AC表头 */
    TableAc(h) {
      return (
        <table>
          <thead>
            <tr style="background:#f5f7fa">
              <th width="40">/</th>
              <th width="70">
                {this.$t("history.pcs.current")} <span class="A"></span>
              </th>
              <th width="70">
                {this.$t("history.pcs.voltage")} <span class="V"></span>
              </th>
            </tr>
          </thead>
        </table>
      );
    },

    /* BMS */
    Bms(h) {
      return <i class="fad fa-3x fa-car-battery"></i>;
    },
    /* 电芯表头  路数,最高温度,最低温度,最高电压,最低电压*/
    TableBattery(h) {
      return (
        <table>
          <thead>
            <tr style="background:#f5f7fa">
              <th width="80">{this.$t("history.bms.number")}</th>
              <th width="80">
                {this.$t("history.bms.maxTemp")} <span class="oc"></span>
              </th>
              <th width="80">
                {this.$t("history.bms.minTemp")} <span class="oc"></span>
              </th>
              <th width="80">
                {this.$t("history.bms.maxVoltage")} <span class="V"></span>
              </th>
              <th width="80">
                {this.$t("history.bms.minVoltage")} <span class="V"></span>
              </th>
               <th width="80">
                Soc <span class="percent"></span>
              </th>
            </tr>
          </thead>
        </table>
      );
    },

    /* 空调表头 */
    AmmeterConditioner(h) {
      return <i class="fad fa-3x fa-snow-blowing"></i>;
    },
    /* 空调表头 */
    AirConditioner(h) {
      return <i class="fad fa-3x fa-snow-blowing"></i>;
    },
    /* 温度 */
    AriTemperatureOc(h) {
      return (
        // 温度
        <div>
          <span>{this.$t("history.ari.temp")}</span>
          <span class="oc small ml-1"></span>
        </div>
      );
    },

    /* DCDC表头 */
    Dcdc(h) {
      return <i class="fad fa-3x fa-tablet-rugged"></i>;
    },
 /* Dcdc模块温度 */
    DcdcModelTemperatureOc(h) {
      return (
        // 温度
        <div>
          <span>{this.$t("history.dcdc.moduleItemp")}</span>
          <span class="oc small ml-1"></span>
        </div>
      );
    },
    /* Dcdc环境温度 */
    DcdcTemperatureOc(h) {
      return (
        // 温度
        <div>
          <span>{this.$t("history.dcdc.temperature")}</span>
          <span class="oc small ml-1"></span>
        </div>
      );
    },
    //表头电路选择按钮
    ChooseCircuit(h) {
      return (
        //电路选择响应切换事件
        <el-row>
          <el-button
            size="mini"
            round
            type="success"
            onClick={() => this.handleAddItem(3)}
            plain
          >
            PV1~PV3
          </el-button>
          <el-button
            size="mini"
            round
            type="success"
            onClick={() => this.handleAddItem(6)}
            plain
          >
            PV4~PV6
          </el-button>
          <el-button
            size="mini"
            round
            type="success"
            onClick={() => this.handleAddItem(9)}
            plain
          >
            PV7~PV9
          </el-button>
          <el-button
            size="mini"
            round
            type="success"
            onClick={() => this.handleAddItem(12)}
            plain
          >
            PV10~PV12
          </el-button>
          <el-button
            size="mini"
            round
            type="success"
            onClick={() => this.handleAddItem(15)}
            plain
          >
            PV13~PV15
          </el-button>
          <el-button
            size="mini"
            round
            type="success"
            onClick={() => this.handleAddItem(18)}
            plain
          >
            PV16~PV18
          </el-button>
          <el-button
            size="mini"
            round
            type="success"
            onClick={() => this.handleAddItem(21)}
            plain
          >
            PV19~PV20
          </el-button>
        </el-row>
      );
    },

    ChooseCircuitBMS(h) {
      return (
        //电路选择响应切换事件
        <el-row>
          <el-button
            size="mini"
            round
            type="success"
            onClick={() => this.handleAddItem(3)}
            plain
          >
            RACK1~RACK3
          </el-button>
          <el-button
            size="mini"
            round
            type="success"
            onClick={() => this.handleAddItem(6)}
            plain
          >
            RACK4~RACK6
          </el-button>
          <el-button
            size="mini"
            round
            type="success"
            onClick={() => this.handleAddItem(9)}
            plain
          >
            RACK7~RACK9
          </el-button>
          <el-button
            size="mini"
            round
            type="success"
            onClick={() => this.handleAddItem(12)}
            plain
          >
            RACK10~RACK12
          </el-button>
          <el-button
            size="mini"
            round
            type="success"
            onClick={() => this.handleAddItem(15)}
            plain
          >
            RACK13~RACK15
          </el-button>
          <el-button
            size="mini"
            round
            type="success"
            onClick={() => this.handleAddItem(18)}
            plain
          >
            RACK16
          </el-button>
        </el-row>
      );
    },
    handleAddItem(DCNum) {
      this.DCNum = DCNum;

      let myVue = this;
      $(".el-table__body-wrapper table tbody").each(function() {
        $(this)
          .find(".lhl-hidden-row")
          .each(function(index) {
            $(this).hide();
            if (!(myVue.DCNum < index + 1 || myVue.DCNum - 2 > index + 1)) {
              $(this).show();
            }
          });
      });
    },
    tableRowClassName: function(data, index) {
      return "lhl-hidden-row";
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        if (rowIndex % 3 === 0) {
          return {
            rowspan: 3,
            colspan: 1
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0
          };
        }
      }
    },

    init() {},
    tablekey() {
      var randomNum = ("000000" + Math.floor(Math.random() * 999999)).slice(-6);
      return "_" + randomNum;
    },
    handlePcsPageChange(name) {
      if (name != "") {
        this.page.activePageName = true;
        this.page.pcsDetail.pageCurrent=1
        this.getPcsDetailsPage(1);
      } else {
        this.page.activePageName = true;
      }
    },

    handleBmsPageChange(name) {
      if (name != "") {
        this.page.activePageName = true;
        this.page.bmsDetail.pageCurrent=1
        this.getRackDetailsPage(1);
      } else {
        this.page.activePageName = true;
      }
    },

    handleAriPageChange(name) {
      if (name != "") {
        this.page.activePageName = true;
        this.page.airDetail.pageCurrent=1
        this.getAirDetailsPage(1);
      } else {
        this.page.activePageName = true;
      }
    },
    handleDCDCPageChange(name) {
      if (name != "") {
        this.page.activePageName = true;
        this.page.dcdcDetail.pageCurrent=1
        this.getDcdcDetailsPage(1);
      } else {
        this.page.activePageName = true;
      }
    },
    handleAmmeterPageChange(name) {
      if (name != "") {
        this.page.activePageName = true;
        this.page.ammeterDetail.pageCurrent=1
        this.getAmmeterDetailsPage(1);
      } else {
        this.page.activePageName = true;
      }
    },
    handleClick(tab, event) {
      //alert(this.char.activeName);
    },

    //电站切换
    handlePsIdChange(psBuilddate, psId,psName) {
      window.sessionStorage.setItem("psName", psName);
      if(this.searchType == null){
         this.searchType = "day"; 
         this.resetFlag=true;
      }
      this.deviceId = null;
      this.buildDate = psBuilddate;
      this.$store.state.buildDate = this.buildDate;
      //this.getQuickDeviceType();
      this.psId = psId;
      this.devPsId = psId;
      this.$emit("parentPsId");
    },
    //切换选中的设备类型
    handleDeviceTypeChange(deviceTypeId) {
      let temp = Math.floor(Math.random() * 999999);
      if ("loading" == deviceTypeId)
        this.checkedDeviceTypeId = "loading," + temp;
      else {
        this.checkedDeviceTypeId = deviceTypeId;
      }
      
      // this.resetFlag = true; 
    },
    //切换设备事件
    handleDeviceChange(deviceId, deviceTypeId) {
      this.deviceId = deviceId;
      this.deviceTypeId = deviceTypeId;
      // if(this.seldeviceId != deviceId){
      //    this.queryflag = false;
      //    this.seldeviceId = null;
      //    this.seldevicetypeid = null;
      // }
      //console.log(this.deviceId);
    },
    //时间事件
    handleSearchDateChange(searchDate, searchType) {
      //切换电站,设备时重置为日
      if(this.resetFlag){
         this.searchType = "day";
        this.resetFlag = false;
      }else{//反之不重置
         this.searchType = searchType;
         this.searchDate = searchDate;
      }
           this.searchType = searchType;
         this.searchDate = searchDate;
      
      console.log("1111111",this.searchType);
      console.log("2222222",this.deviceId);
      console.log("3333333",this.deviceTypeId);
      switch (this.searchType) {
        case "year":
          this.yearDeviceType();
          break;
        case "month":
          this.monthDeviceType();
          break;
        case "day":
          this.dayDeviceType();
          break;
      }
    },

    // pcs分页点击
    handleCurrentChange(page) {
      this.getPcsDetailsPage(page);
    },
    //bms分页点击
    handleBmsCurrentChange(page) {
      this.getRackDetailsPage(page);
    },

    handleAriCurrentChange(page) {
      this.getAirDetailsPage(page);
    },

    handleDcdcCurrentChange(page) {
      this.getDcdcDetailsPage(page);
    },
    handleAmmeterCurrentChange(page) {
      this.getAmmeterDetailsPage(page);
    },
    //
    dayDeviceType() {
      //debugger
      if(this.deviceTypeId !=null ){
        this.deviceTypeId = parseInt(this.deviceTypeId);
          switch (this.deviceTypeId) {  
          case 1:
            this.getPcsDetails();
            if (this.page.activePageName) {
              this.page.pcsDetail.pageCurrent=1
              this.getPcsDetailsPage(1);
            }
            break;
          case 2:
            this.getDcdcDetails();
            if (this.page.activePageName) {
              this.page.dcdcDetail.pageCurrent=1
              this.getDcdcDetailsPage(1);
            }

            break;
          case 3:
            this.getRackDetails();
            if (this.page.activePageName) {
              this.page.bmsDetail.pageCurrent=1
              this.getRackDetailsPage(1);
            }

            break;
          case 4:
            this.getAirDetails();
            if (this.page.activePageName) {
              this.page.airDetail.pageCurrent=1
              this.getAirDetailsPage(1);
            }

            break;
         case 5:
           this.getAmmterDetails();
            if (this.page.activePageName) {
              this.page.ammeterDetail.pageCurrent=1
              this.getAmmeterDetailsPage(1);
            }
        }
      } 
    },
    monthDeviceType() {
     // alert(this.deviceTypeId)
      switch (this.deviceTypeId) {
        case 1:
          this.getPcsMonthDay();
          break;
        case 2:
          this.getDcdcMonthDay();
          break;
        case 3:
          this.getBmsMonthDay();
          break;
        case 4:
          this.getAirMonthDay();
          break;
      }
    },
    yearDeviceType() {
      switch (this.deviceTypeId) {
        case 1:
          this.getPcsYearMonth();
          break;
        case 2:
          this.getDcdcYearMonth();
          break;
      }
    },

    //获取pcs明细数据
    async getPcsDetails() {
      try {
        if (this.deviceId == null) {
          return false;
        }
        this.loading = true;
        this.char.pcsDetail = [];
        let userId = window.sessionStorage.getItem("userId");
        const { data: res } = await this.$http.get(
          "/service-device/pcs/getPcsDetails?deviceId=" +
            this.deviceId +
            "&userId=" +
            userId +
            "&searchDate=" +
            this.searchDate,
          {}
        );
        if (res.status == 200) {
           this.formatReport(res.data)
           this.reportData=res.data
          this.loading = false;
          this.char.pcsDetail = res.data;
        } else {
          this.loading = false;
          this.char.pcsDetail = [];
          //this.deviceList=[];
        }
      } catch (err) {
        //this.$message.error("查询超时")
        //swal(this.$t('history.timeOut'), this.$t('history.pleaseTryAgain'), "error");
        swal({
          title: this.$t("history.timeOut"),
          text: this.$t("history.pleaseTryAgain"),
          type: "error",
          confirmButtonText: this.$t("history.msg.confirm")
        });
        this.loading = false;
        this.char.pcsDetail = [];
      }
    },

    async getPcsDetailsPage(currentPage) {
      try {
        if (this.deviceId == null) {
          return false;
        }
        this.loadingPage = true;

        let userId = window.sessionStorage.getItem("userId");
        const { data: res } = await this.$http.get(
          "/service-device/pcs/getPcsDetailsPage?deviceId=" +
            this.deviceId +
            "&currentPage=" +
            currentPage +
            "&pageCount=" +
            this.page.pcsDetail.pageCount +
            "&userId=" +
            userId +
            "&searchDate=" +
            this.searchDate,
          {}
        );
        if (res.status == 200) {
          this.loadingPage = false;
          console.log("abc");
          //this.page.pcsDetail=res.data;
          this.page.pcsDetail.total = res.data["records"];
          let tempPageItems = res.data["rows"];
          let pageItems = [];
          for (let i = 0; i < tempPageItems.length; i++) {
            let item = {};
            item["getdatetime"] = tempPageItems[i]["getdatetime"].split(" ")[1];
            item["pac"] = tempPageItems[i]["pac"];
            item["rFac"] = tempPageItems[i]["rFac"];
            item["itemp"] = tempPageItems[i]["itemp"];
            item["temp"] = tempPageItems[i]["temp"];
            item["totalPac"] = tempPageItems[i]["totalPac"];

            item["dayPac"] = tempPageItems[i]["dayPac"];
            item["dayInTotal"] = tempPageItems[i]["dayInTotal"];
            item["inTotal"] = tempPageItems[i]["inTotal"];
            item["dayOutTotal"] = tempPageItems[i]["dayOutTotal"];
            item["outTotal"] = tempPageItems[i]["outTotal"];
            // item["Vacs"]=[{"Vac":tempPageItems[i]["rVac"]},{"Vac":tempPageItems[i]["sVac"]},{"Vac":tempPageItems[i]["tVac"]}];
            // item["Iacs"]=[{"Iac":tempPageItems[i]["rIac"]},{"Iac":tempPageItems[i]["sIac"]},{"Iac":tempPageItems[i]["tIac"]}];

            item["AC"] = [];
            let itemAC = {
              name: "A",
              Vac: tempPageItems[i]["rVac"],
              Iac: tempPageItems[i]["rIac"]
            };
            item["AC"].push(itemAC);

            itemAC = {
              name: "B",
              Vac: tempPageItems[i]["sVac"],
              Iac: tempPageItems[i]["sIac"]
            };
            item["AC"].push(itemAC);

            itemAC = {
              name: "C",
              Vac: tempPageItems[i]["tVac"],
              Iac: tempPageItems[i]["tIac"]
            };
            item["AC"].push(itemAC);

            let ppvs = tempPageItems[i]["ppvs"];
            let ipvs = tempPageItems[i]["ipvs"];
            let vpvs = tempPageItems[i]["vpvs"];

            item["DC"] = [];
            for (let i = 0; i < 20; i++) {
              let itemDC = {};
              //itemDC["PV"+(i+1)]={"ppv":(ppvs.length>i?ppvs[i]:"-")}
              itemDC["index"] = i + 1;
              itemDC["name"] = "PV" + (i + 1);
              itemDC["ppv"] = ppvs.length > i ? ppvs[i] : "-";
              itemDC["ipv"] = ipvs.length > i ? ipvs[i] : "-";
              itemDC["vpv"] = vpvs.length > i ? vpvs[i] : "-";
              item["DC"].push(itemDC);
            }
            pageItems.push(item);
            console.log("abc" + JSON.stringify(pageItems));
          }
          this.page.pcsDetail.rows = pageItems;
        } else {
          this.loadingPage = false;
          this.page.pcsDetail = { total: 0, rows: [], pageCount: 10 };
          //this.deviceList=[]
        }
      } catch (err) {
        //this.$message.error("查询超时")
        //swal(this.$t('history.timeOut'), this.$t('history.pleaseTryAgain'), "error");
        swal({
          title: this.$t("history.timeOut"),
          text: this.$t("history.pleaseTryAgain"),
          type: "error",
          confirmButtonText: this.$t("history.msg.confirm")
        });
      }
    },
    //pcs 月下每日查询
    async getPcsMonthDay() {
      var _this=this;
      try {
        if (this.deviceId == null) {
          return false;
        }
        this.char.pcsDays = [];
        this.loading = true;
        let userId = window.sessionStorage.getItem("userId");
        const { data: res } = await this.$http.get(
          "/service-device/pcs/getPcsMonthDay?deviceId=" +
            this.deviceId +
            "&userId=" +
            userId +
            "&searchDate=" +
            this.searchDate,
          {}
        );
        if (res.status == 200) {
          _this.formatReport(res.data)
          this.reportData=res.data
          this.char.pcsDays = res.data;
          this.loading = false;
        } else {
          //this.deviceList=[];
          this.loading = false;
          this.char.pcsDays = [];
        }
      } catch (err) {
        //swal(this.$t('history.timeOut'), this.$t('history.pleaseTryAgain'), "error");
        swal({
          title: this.$t("history.timeOut"),
          text: this.$t("history.pleaseTryAgain"),
          type: "error",
          confirmButtonText: this.$t("history.msg.confirm")
        });
        //this.$message.error("查询超时")
        this.loading = false;
        this.char.pcsDays = [];
      }
    },
    //pcs 年下每月查询
    async getPcsYearMonth() {
      try {
        if (this.deviceId == null) {
          return false;
        }
        this.char.pcsMonths = [];
        this.loading = true;
        let userId = window.sessionStorage.getItem("userId");
        const { data: res } = await this.$http.get(
          "/service-device/pcs/getPcsYearMonth?deviceId=" +
            this.deviceId +
            "&userId=" +
            userId +
            "&searchDate=" +
            this.searchDate,
          {}
        );
        if (res.status == 200) {
          console.log(JSON.stringify(res.data));
          this.char.pcsMonths = res.data;
          this.loading = false;
        } else {
          this.loading = false;
          //this.deviceList=[];
          this.char.pcsMonths = [];
        }
      } catch (err) {
        // this.$message.error("查询超时")
        //swal(this.$t('history.timeOut'), this.$t('history.pleaseTryAgain'), "error");
        swal({
          title: this.$t("history.timeOut"),
          text: this.$t("history.pleaseTryAgain"),
          type: "error",
          confirmButtonText: this.$t("history.msg.confirm")
        });
        this.loading = false;
        this.char.pcsMonths = [];
      }
    },
    //获取bms明细数据
    async getRackDetails() {
      try {
        if (this.deviceId == null) {
          return false;
        }
        this.loading = true;
        
        this.char.bmsDetail = [];
        let userId = window.sessionStorage.getItem("userId");
        const { data: res } = await this.$http.get(
          "/service-device/rack/getRackDetails?deviceId=" +
            this.deviceId +
            "&userId=" +
            userId +
            "&searchDate=" +
            this.searchDate,
          {}
        );
        if (res.status == 200) {
          this.formatReport(res.data)
          this.reportData=res.data
          this.char.bmsDetail = res.data;
          this.loading = false;
        } else {
          this.loading = false;
          this.char.bmsDetail = [];
          //this.deviceList=[];
        }
      } catch (err) {
        //this.$message.error("查询超时")
        //swal(this.$t('history.timeOut'), this.$t('history.pleaseTryAgain'), "error");
        swal({
          title: this.$t("history.timeOut"),
          text: this.$t("history.pleaseTryAgain"),
          type: "error",
          confirmButtonText: this.$t("history.msg.confirm")
        });
        this.loading = false;
        this.char.bmsDetail = [];
      }
    },
    //bms 月下每日查询
    async getBmsMonthDay() {
      try {
        if (this.deviceId == null) {
          return false;
        }
        this.char.bmsDays = [];
        this.loading = true;
        let userId = window.sessionStorage.getItem("userId");
        const { data: res } = await this.$http.get(
          "/service-device/rack/getRackMonthDay?deviceId=" +
            this.deviceId +
            "&userId=" +
            userId +
            "&searchDate=" +
            this.searchDate,
          {}
        );
        if (res.status == 200) {
           this.formatReport(res.data)
          this.reportData=res.data
          this.char.bmsDays = res.data;
          this.loading = false;
        } else {
          //this.deviceList=[];
          this.loading = false;
          this.char.bmsDays = [];
        }
      } catch (err) {
        //swal(this.$t('history.timeOut'), this.$t('history.pleaseTryAgain'), "error");
        swal({
          title: this.$t("history.timeOut"),
          text: this.$t("history.pleaseTryAgain"),
          type: "error",
          confirmButtonText: this.$t("history.msg.confirm")
        });
        //this.$message.error("查询超时")
        this.loading = false;
        this.char.bmsDays = [];
      }
    },

    // bms分页
    async getRackDetailsPage(currentPage) {
      try {
        if (this.deviceId == null) {
          return false;
        }
        this.loadingPage = true;

        let userId = window.sessionStorage.getItem("userId");
        const { data: res } = await this.$http.get(
          "/service-device/rack/getRackDetailsPage?deviceId=" +
            this.deviceId +
            "&currentPage=" +
            currentPage +
            "&pageCount=" +
            this.page.pcsDetail.pageCount +
            "&userId=" +
            userId +
            "&searchDate=" +
            this.searchDate,
          {}
        );
        if (res.status == 200) {
          this.loadingPage = false;

          //this.page.pcsDetail=res.data;
          this.page.bmsDetail.total = res.data["records"];
          let tempPageItems = res.data["rows"];
          let pageItems = [];
          for (let i = 0; i < tempPageItems.length; i++) {
            let item = {};
            item["getdatetime"] = tempPageItems[i]["getdatetime"].split(" ")[1];
            item["bv"] =
              tempPageItems[i]["bv"] == null ? "-" : tempPageItems[i]["bv"];
            item["bcc"] =
              tempPageItems[i]["bcc"] == null ? "-" : tempPageItems[i]["bcc"];
            item["bcp"] =
              tempPageItems[i]["bcp"] == null ? "-" : tempPageItems[i]["bcp"];
            item["soc"] =
              tempPageItems[i]["soc"] == null ? "-" : tempPageItems[i]["soc"];

            let maxTemps =
              tempPageItems[i]["mtMaxList"] == null
                ? []
                : tempPageItems[i]["mtMaxList"];
            let minTemps =
              tempPageItems[i]["mtMinList"] == null
                ? []
                : tempPageItems[i]["mtMinList"];
            let maxBvs =
              tempPageItems[i]["mvMaxList"] == null
                ? []
                : tempPageItems[i]["mvMaxList"];
            let minBvs =
              tempPageItems[i]["mvMinList"] == null
                ? []
                : tempPageItems[i]["mvMinList"];
            let mSoc =
              tempPageItems[i]["msocList"] == null
                ? []
                : tempPageItems[i]["msocList"];
            item["tempBvs"] = [];
            for (let i = 0; i < 16; i++) {
              let itemtempBv = {};
              //itemDC["PV"+(i+1)]={"ppv":(ppvs.length>i?ppvs[i]:"-")}
              itemtempBv["index"] = i + 1;
              itemtempBv["name"] = "Rack" + (i + 1);
              itemtempBv["maxTemp"] = maxTemps.length > i ? maxTemps[i] : "-";
              itemtempBv["minTemp"] = minTemps.length > i ? minTemps[i] : "-";
              itemtempBv["maxBv"] = maxBvs.length > i ? maxBvs[i] : "-";
              itemtempBv["minBv"] = minBvs.length > i ? minBvs[i] : "-";
              itemtempBv["mSoc"] = mSoc.length > i ? mSoc[i] : "-";    
              item["tempBvs"].push(itemtempBv);
            }
            pageItems.push(item);
          }
          this.page.bmsDetail.rows = pageItems;
        } else {
          this.loadingPage = false;
          this.page.pcsDetail = { total: 0, rows: [], pageCount: 10 };
          //this.deviceList=[]
        }
      } catch (err) {
        //this.$message.error("查询超时")
        //swal(this.$t('history.timeOut'), this.$t('history.pleaseTryAgain'), "error");
        swal({
          title: this.$t("history.timeOut"),
          text: this.$t("history.pleaseTryAgain"),
          type: "error",
          confirmButtonText: this.$t("history.msg.confirm")
        });
      }
    },

    //获取dcdc明细数据
    async getDcdcDetails() {
      try {
        if (this.deviceId == null) {
          return false;
        }
        this.loading = true;
        this.char.dcdcDetail = [];
        let userId = window.sessionStorage.getItem("userId");
        const { data: res } = await this.$http.get(
          "/service-device/dcdc/getDcdcDetails?deviceId=" +
            this.deviceId +
            "&userId=" +
            userId +
            "&searchDate=" +
            this.searchDate,
          {}
        );
        if (res.status == 200) {
          this.formatReport(res.data)
          this.reportData=res.data
          this.char.dcdcDetail = res.data;
          this.loading = false;
        } else {
          this.loading = false;
          this.char.dcdcDetail = [];
          //this.deviceList=[];
        }
      } catch (err) {
        this.char.dcdcDetail = [];
        //this.$message.error("查询超时")
        //swal(this.$t('history.timeOut'), this.$t('history.pleaseTryAgain'), "error");
        swal({
          title: this.$t("history.timeOut"),
          text: this.$t("history.pleaseTryAgain"),
          type: "error",
          confirmButtonText: this.$t("history.msg.confirm")
        });
        this.loading = false;
      }
    },

    //dcdc分页数据
    async getDcdcDetailsPage(currentPage) {
      try {
        if (this.deviceId == null) {
          return false;
        }
        this.loadingPage = true;

        let userId = window.sessionStorage.getItem("userId");
        const { data: res } = await this.$http.get(
          "/service-device/dcdc/getDcdcDetailsPage?deviceId=" +
            this.deviceId +
            "&currentPage=" +
            currentPage +
            "&pageCount=" +
            this.page.pcsDetail.pageCount +
            "&userId=" +
            userId +
            "&searchDate=" +
            this.searchDate,
          {}
        );
        if (res.status == 200) {
          this.loadingPage = false;

          //this.page.pcsDetail=res.data;
          this.page.dcdcDetail.total = res.data["records"];
          let tempPageItems = res.data["rows"];
          let pageItems = [];
          for (let i = 0; i < tempPageItems.length; i++) {
            let item = {};
            item["getdatetime"] = tempPageItems[i]["getdatetime"].split(" ")[1];
            item["deviceFlag"] =
              tempPageItems[i]["deviceFlag"] == null
                ? "-"
                : tempPageItems[i]["deviceFlag"];
            switch (item["deviceFlag"]) {
              case 0:
                item["deviceFlag"] = this.$t("history.dcdc.normal"); //"正常"
                break;
              case 1:
                item["deviceFlag"] = this.$t("history.dcdc.offLine"); //"离线"
                break;
               case 3:
                item["deviceFlag"] = this.$t("home.shutDown"); //"停机"
                break;
              case 4:
                item["deviceFlag"] = this.$t("history.dcdc.fault"); //"故障"
                break;
              default:
                item["deviceFlag"] = "-";
                break;
            }
            item["ppv"] =
              tempPageItems[i]["ppv"] == null ? "-" : tempPageItems[i]["ppv"];
            item["ipv"] =
              tempPageItems[i]["ipv"] == null ? "-" : tempPageItems[i]["ipv"];
            item["vpv"] =
              tempPageItems[i]["vpv"] == null ? "-" : tempPageItems[i]["vpv"];
            item["itemp"] =
              tempPageItems[i]["itemp"] == null ? "-" : tempPageItems[i]["itemp"];
            item["temp"] =
              tempPageItems[i]["temp"] == null ? "-" : tempPageItems[i]["temp"];
            pageItems.push(item);
          }
          this.page.dcdcDetail.rows = pageItems;
        } else {
          this.loadingPage = false;
          this.page.dcdcDetail = { total: 0, rows: [], pageCount: 10 };
          //this.deviceList=[]
        }
      } catch (err) {
        //this.$message.error("查询超时")
        //swal(this.$t('history.timeOut'), this.$t('history.pleaseTryAgain'), "error");
        swal({
          title: this.$t("history.timeOut"),
          text: this.$t("history.pleaseTryAgain"),
          type: "error",
          confirmButtonText: this.$t("history.msg.confirm")
        });
      }
    },

    //获取dcdc月下每日
    async getDcdcMonthDay() {
      try {
        if (this.deviceId == null) {
          return false;
        }
        this.loading = true;
        this.char.dcdcDays = [];
        let userId = window.sessionStorage.getItem("userId");
        const { data: res } = await this.$http.get(
          "/service-device/dcdc/getDcdcMonthDay?deviceId=" +
            this.deviceId +
            "&userId=" +
            userId +
            "&searchDate=" +
            this.searchDate,
          {}
        );
        if (res.status == 200) {
          this.formatReport(res.data)
          this.reportData=res.data
          this.char.dcdcDays = res.data;
          this.loading = false;
        } else {
          this.loading = false;
          this.char.dcdcDays = [];
          //this.deviceList=[];
        }
      } catch (err) {
        //this.$message.error("查询超时")
        //his.$t('history.timeOut'), this.$t('history.pleaseTryAgain'), "error");
        swal({
          title: this.$t("history.timeOut"),
          text: this.$t("history.pleaseTryAgain"),
          type: "error",
          confirmButtonText: this.$t("history.msg.confirm")
        });
        this.loading = false;
        this.char.dcdcDays = [];
      }
    },

    //获取dcdc月下每日
    async getDcdcYearMonth() {
      try {
        if (this.deviceId == null) {
          return false;
        }
        this.loading = true;
        this.char.dcdcMonths = [];
        let userId = window.sessionStorage.getItem("userId");
        const { data: res } = await this.$http.get(
          "/service-device/dcdc/getDcdcYearMonth?deviceId=" +
            this.deviceId +
            "&userId=" +
            userId +
            "&searchDate=" +
            this.searchDate,
          {}
        );
        if (res.status == 200) {
          this.char.dcdcMonths = res.data;
          this.loading = false;
        } else {
          this.loading = false;
          this.char.dcdcMonths = [];
          //this.deviceList=[];
        }
      } catch (err) {
        //this.$message.error("查询超时")
        //swal(this.$t('history.timeOut'), this.$t('history.pleaseTryAgain'), "error");
        swal({
          title: this.$t("history.timeOut"),
          text: this.$t("history.pleaseTryAgain"),
          type: "error",
          confirmButtonText: this.$t("history.msg.confirm")
        });
        this.loading = false;
        this.char.dcdcMonths = [];
      }
    },

    //获取 air明细数据
    async getAirDetails() {
      try {
        if (this.deviceId == null) {
          return false;
        }
        this.loading = true;
        this.char.airDetail = [];
        let userId = window.sessionStorage.getItem("userId");
        const { data: res } = await this.$http.get(
          "/service-device/air/getAirDetails?deviceId=" +
            this.deviceId +
            "&userId=" +
            userId +
            "&searchDate=" +
            this.searchDate,
          {}
        );
        if (res.status == 200) {
          this.formatReport(res.data)
          this.reportData=res.data
          this.char.airDetail = res.data;
          this.loading = false;
        } else {
          this.loading = false;
          this.char.airDetail = [];
          //this.deviceList=[];
        }
      } catch (err) {
        //this.$message.error("查询超时")
        //swal(this.$t('history.timeOut'), this.$t('history.pleaseTryAgain'), "error");
        swal({
          title: this.$t("history.timeOut"),
          text: this.$t("history.pleaseTryAgain"),
          type: "error",
          confirmButtonText: this.$t("history.msg.confirm")
        });
        this.loading = false;
        this.char.airDetail = [];
      }
    },

    //空调 月下每日查询
    async getAirMonthDay() {
      try {
        if (this.deviceId == null) {
          return false;
        }
        this.char.airDays = [];
        this.loading = true;
        let userId = window.sessionStorage.getItem("userId");
        const { data: res } = await this.$http.get(
          "/service-device/air/getAirMonthDay?deviceId=" +
            this.deviceId +
            "&userId=" +
            userId +
            "&searchDate=" +
            this.searchDate,
          {}
        );
        if (res.status == 200) {
          this.formatReport(res.data)
          this.reportData=res.data
          this.char.airDays = res.data;
          this.loading = false;
        } else {
          //this.deviceList=[];
          this.loading = false;
          this.char.airDays = [];
        }
      } catch (err) {
        //swal(this.$t('history.timeOut'), this.$t('history.pleaseTryAgain'), "error");
        swal({
          title: this.$t("history.timeOut"),
          text: this.$t("history.pleaseTryAgain"),
          type: "error",
          confirmButtonText: this.$t("history.msg.confirm")
        });
        //this.$message.error("查询超时")
        this.loading = false;
        this.char.airDays = [];
      }
    },
    // 空调分页
    async getAirDetailsPage(currentPage) {
      try {
        if (this.deviceId == null) {
          return false;
        }
        this.loadingPage = true;

        let userId = window.sessionStorage.getItem("userId");
        const { data: res } = await this.$http.get(
          "/service-device/air/getAirDetailsPage?deviceId=" +
            this.deviceId +
            "&currentPage=" +
            currentPage +
            "&pageCount=" +
            this.page.airDetail.pageCount +
            "&userId=" +
            userId +
            "&searchDate=" +
            this.searchDate,
          {}
        );
        if (res.status == 200) {
          this.loadingPage = false;

          //this.page.pcsDetail=res.data;
          this.page.airDetail.total = res.data["records"];
          let tempPageItems = res.data["rows"];
          let pageItems = [];
          for (let i = 0; i < tempPageItems.length; i++) {
            let item = {};
            item["getdatetime"] = tempPageItems[i]["getdatetime"].split(" ")[1];
            item["deviceFlag"] =
              tempPageItems[i]["deviceFlag"] == null
                ? "-"
                : tempPageItems[i]["deviceFlag"];
            switch (item["deviceFlag"]) {
              case 0:
                item["deviceFlag"] = this.$t("history.ari.normal"); //"正常"
                break;
              case 1:
                item["deviceFlag"] = this.$t("history.ari.offLine"); //"离线"
                break;
              case 3:
                item["deviceFlag"] = this.$t("home.shutDown"); //"停机"
                break;  
              case 4:
                item["deviceFlag"] = this.$t("history.ari.fault"); //"故障"
                break;
              default:
                item["deviceFlag"] = "-";
                break;
            }
            item["indoortemp"] =
              tempPageItems[i]["indoortemp"] == null
                ? "-"
                : tempPageItems[i]["indoortemp"];
            pageItems.push(item);
          }
          this.page.airDetail.rows = pageItems;
          console.log(JSON.stringify(this.page.airDetail));
        } else {
          this.loadingPage = false;
          this.page.airDetail = { total: 0, rows: [], pageCount: 10 };
          //this.deviceList=[]
        }
      } catch (err) {
        //this.$message.error("查询超时")
        //swal(this.$t('history.timeOut'), this.$t('history.pleaseTryAgain'), "error");
        swal({
          title: this.$t("history.timeOut"),
          text: this.$t("history.pleaseTryAgain"),
          type: "error",
          confirmButtonText: this.$t("history.msg.confirm")
        });
      }
    },
    //获取 ammter明细数据
    async getAmmterDetails() {
      try {
        if (this.deviceId == null) {
          return false;
        }
        this.loading = true;
        this.char.ammterDetail = [];
        let userId = window.sessionStorage.getItem("userId");
        const { data: res } = await this.$http.get(
          "/service-device/ammeter/getAmmeterDetails?deviceId=" +
            this.deviceId +
            "&userId=" +
            userId +
            "&searchDate=" +
            this.searchDate,
          {}
        );
        if (res.status == 200) {
          this.formatReport(res.data)
          this.reportData=res.data
          this.char.ammterDetail = res.data;
          this.loading = false;
        } else {
          this.loading = false;
          this.char.ammterDetail = [];
          //this.deviceList=[];
        }
      } catch (err) {
        //this.$message.error("查询超时")
        //swal(this.$t('history.timeOut'), this.$t('history.pleaseTryAgain'), "error");
        swal({
          title: this.$t("history.timeOut"),
          text: this.$t("history.pleaseTryAgain"),
          type: "error",
          confirmButtonText: this.$t("history.msg.confirm")
        });
        this.loading = false;
        this.char.ammterDetail = [];
      }
    },
      // 电表分页
    async getAmmeterDetailsPage(currentPage) {
      try {
        if (this.deviceId == null) {
          return false;
        }
        this.loadingPage = true;

        let userId = window.sessionStorage.getItem("userId");
        const { data: res } = await this.$http.get(
          "/service-device/ammeter/getAmmeterDetailsPage?deviceId=" +
            this.deviceId +
            "&currentPage=" +
            currentPage +
            "&pageCount=" +
            this.page.ammeterDetail.pageCount +
            "&userId=" +
            userId +
            "&searchDate=" +
            this.searchDate,
          {}
        );
        if (res.status == 200) {
          this.loadingPage = false;

          //this.page.pcsDetail=res.data;
          this.page.ammeterDetail.total = res.data["records"];
          let tempPageItems = res.data["rows"];
          let pageItems = [];
          for (let i = 0; i < tempPageItems.length; i++) {
            let item = {};
            item["getdatetime"] = tempPageItems[i]["getdatetime"].split(" ")[1];
            item["pac"] =
              tempPageItems[i]["pac"] == null
                ? "-"
                : tempPageItems[i]["pac"];
            pageItems.push(item);
          }
          this.page.ammeterDetail.rows = pageItems;
          console.log(JSON.stringify(this.page.ammeterDetail));
        } else {
          this.loadingPage = false;
          this.page.ammeterDetail = { total: 0, rows: [], pageCount: 10 };
          //this.deviceList=[]
        }
      } catch (err) {
        //this.$message.error("查询超时")
        //swal(this.$t('history.timeOut'), this.$t('history.pleaseTryAgain'), "error");
        swal({
          title: this.$t("history.timeOut"),
          text: this.$t("history.pleaseTryAgain"),
          type: "error",
          confirmButtonText: this.$t("history.msg.confirm")
        });
      }
    },
  }
};
</script>

<style>
.el-table td,
.el-table th {
  text-align: center !important;
}

/* .el-table__body-wrapper::-webkit-scrollbar {
  width: 16px;
  height: 4px;
} */

.el-table__body-wrapper::-webkit-scrollbar {
  width: 0.825rem;
  height: 0.825rem;
}
.el-table__body-wrapper::-webkit-scrollbar-thumb {
  border-radius: 0.625rem;
  background-color: rgba(103, 194, 58, 0.15);
  transition: 0.3s background-color;
}
.el-table .hidden-row {
  display: none;
}
</style>
