<template>
  <div class="tab-pane show vivify fadeIn active"
       style="height:500px;">
    <div id="bms-bcp-char-detail"
         v-loading="loading"
         :style="{width:'100%',height:'100%','text-align':'center','line-height':'400px'}">{{$t("history.noData")}}</div>
  </div>
</template>

<script>
import {getMaxMinValue,getMaxMinSet} from '../../utils/tools.js'
export default {
  data () {
    return {
      myChart: null,
      loading: false,
      maxValueKW: 0,
      minValueKW: 0,
      maxValueSoc: 0,
      minValueSoc: 0,
    }
  },
  props: ['searchBtn', 'charData', 'resizeName'],
  watch: {
    searchBtn: {
      handler (searchBtn) {
        if (searchBtn != "") {
          this.pageLoad();
        }
      }
    },
    charData: {
      handler () {
        this.pageLoad();
      }
    },
    resizeName: {
      handler () {
        this.myChart.resize();
      }
    }
  },
  methods: {
    pageLoad () {
      if (this.charData != null && this.charData.length > 0) {
        this.detailChar();
      } else {
        var chartDom = document.getElementById('bms-bcp-char-detail');
        chartDom.style.cssText = "text-align:center;color: #999; border: none;line-height: 400px;width: 100%; height: 100%; -webkit-tap-highlight-color: transparent; user-select: none; position: relative;";
        chartDom.innerHTML = this.$t("history.noData");
        chartDom.removeAttribute('_echarts_instance_');
      }
    },
    detailChar () {
      //设置KW单位最大最小值
      this.maxValueKW = 0;
      this.minValueKW = 0;
      this.maxValueSoc = 0;
      this.minValueSoc = 0;
      console.log(this.charData);
      //时间
      let timesData = [];
      let socData = [];
      //交流功率
      let pacData = [];
      this.loading = true;
      if (this.charData != null && this.charData.length > 0) {
        for (let i = 0; i < this.charData.length; i++) {
          let tt = ((this.charData[i]["getdatetime"]).split(' '))[1];
          tt = tt.split(':')
          timesData.push(tt[0] + ":" + tt[1]);
          let bcpval = this.charData[i]["bcp"] == null ? 0 : this.charData[i]["bcp"]
          pacData.push(bcpval);
          let socval = this.charData[i]["soc"] == null ? 0 : this.charData[i]["soc"]
          socData.push(socval);

          this.maxValueKW = parseFloat(bcpval) > parseFloat(this.maxValueKW) ? bcpval : this.maxValueKW;
          this.minValueKW = parseFloat(bcpval) < parseFloat(this.minValueKW) ? bcpval : this.minValueKW;
          this.maxValueSoc = parseFloat(socval) > parseFloat(this.maxValueSoc) ? socval : this.maxValueSoc;
          this.minValueSoc = parseFloat(socval) < parseFloat(this.minValueSoc) ? socval : this.minValueSoc;
        }
        let jsonMaxMinKW = getMaxMinValue(this.maxValueKW, this.minValueKW)
        let jsonMaxMinSoc = getMaxMinValue(this.maxValueSoc, this.minValueSoc)
        this.maxValueKW = jsonMaxMinKW["max"];
        this.minValueKW = jsonMaxMinKW["min"];
        this.maxValueSoc = jsonMaxMinSoc["max"];
        this.minValueSoc = jsonMaxMinSoc["min"]; 
        if(jsonMaxMinKW["flag"] || jsonMaxMinSoc["flag"]){
           let jsonMaxMinKW = getMaxMinSet(this.maxValueKW, this.minValueKW)
           this.maxValueKW = jsonMaxMinKW["max"];
           this.minValueKW = jsonMaxMinKW["min"];
           let jsonMaxMinSoc = getMaxMinSet(this.maxValueSoc, this.minValueSoc)
           this.maxValueSoc = jsonMaxMinSoc["max"];
           this.minValueSoc = jsonMaxMinSoc["min"]; 
        }
        
      }
      // console.info("maxYAxisNum",maxYAxisNum)
      //  console.info("minYAxisNum",minYAxisNum)

      console.log("abc" + pacData)
      var chartDom = document.getElementById('bms-bcp-char-detail');
      this.myChart = this.$echarts.init(chartDom, 'macarons');
      var option;
      option = {
        //图形上下右左边距设置
        grid: {
          left: '3%',
          right: '1%',
          bottom: '10%',
          containLabel: true
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: { type: 'cross' }
        },
        //color: ['#007bff', '#91cc75', '#91cc75', '#fc8452', '#9a60b4', '#ea7ccc', '#91cc75'],
        legend: {

          orient: 'horizontal',
          bottom: 10,
          icon: 'stack',
        },
        xAxis: [
          {
            type: 'category',

            axisTick: {
              alignWithLabel: true
            },
            data: timesData
          }
        ],
        yAxis: [
          {
            type: 'value',
            // name: this.$t('history.bms.char.inOutPower') + '＆SOC',
            min: this.minValueKW,
            max: this.maxValueKW,
            position: 'left',
            axisLabel: {
              formatter: '{value} kW'
            }
          },
          {
            type: 'value',
            // name: 'SOC (%)',
            min: this.minValueSoc,
            max: this.maxValueSoc,
            position: 'right',
            axisLabel: {
              formatter: '{value} %'
            }
          }
        ],
        series: [
          {
            name: this.$t('history.bms.char.inOutPower') + '(kW)',
            type: 'line',
            smooth: true,
            yAxisIndex: 0,
            data: pacData,
            emphasis: {
              focus: 'series'
            },
            areaStyle: {
              //color:'#007bff',
              opacity: 0.8
            },
            lineStyle: {
              width: 0
            },
            itemStyle: {
              opacity: 0,
            }
          },
          {
            name: 'SOC (%)',
            type: 'line',
            smooth: true,
            yAxisIndex: 1,
            data: socData,
            emphasis: {
              focus: 'series'
            },
            // areaStyle: {
            //   //color:'#007bff',
            //   opacity: 0.8
            // },
            // lineStyle: {
            //   width: 0
            // },
            // itemStyle: {
            //   opacity: 0,
            // }
          }
        ]
      };
      option && this.myChart.setOption(option, true);
      this.loading = false;
      return true;
    }
  }
}
</script>